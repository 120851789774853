import * as LateSubmissionUtil from '@kritik/utils/creation/lateCreation';
import InlineInformation from 'components/layout/InlineInformation';
import { TranslatedText } from 'components/TranslatedText';
import { localize } from 'locales/index';

const RejectedCreation = ({ creation }: any) => {
  if (!LateSubmissionUtil.isLateSubmissionRejected(creation)) {
    return null;
  }
  return (
    <div className="late-submission__rejection-notice">
      <InlineInformation
        type="danger"
        title={localize({ message: 'RejectedCreation.LateRejected' })}
      >
        <TranslatedText i18nKey="RejectedCreation.StudentWillReceiveNoScore" />
      </InlineInformation>
    </div>
  );
};

export default RejectedCreation;
