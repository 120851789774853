import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
import { navigateToActivityList } from 'actions/activity';
import { selectCourse } from 'actions/select';
import FormSubmitButtons from 'components/core/form/SubmitButtons';
import PageContainer from 'components/layout/PageContainer';
import PageHeader from 'components/layout/PageHeader';
import Button from 'components/buttons/Button';
import CourseSelector from 'containers/ActivityImport/CourseSelector';
import ActivityImportTable from 'containers/ActivityImport/ActivityImportTable';
import ProgressModal from 'containers/ActivityImport/ProgressModal';
import { selectActivityIdsToImport } from 'redux/activityImport/select';
import { importSelectedActivities, resetStore } from 'redux/activityImport/actions';
import { TranslatedText } from 'components/TranslatedText';

const ActivityImport = (props: any) => {
  const [isImporting, setIsImporting] = useState(false);

  useEffect(() => {
    props.resetStore();
    props.selectCourse(props.router.params.courseId);
  }, []);

  const handleActivityImport = () => {
    if (!isImporting) {
      setIsImporting(true);
      props.importSelectedActivities();
    }
  };

  return (
    <PageContainer>
      <PageHeader title={<TranslatedText i18nKey="ActivityImport.PageHeader" />} />
      <CourseSelector />
      <ActivityImportTable />
      <FormSubmitButtons>
        <Button
          type="primary"
          onClick={handleActivityImport}
          disabled={props.activitiesToImport.length === 0}
          unavailable={props.activitiesToImport.length === 0}
          testid="import-activities"
        >
          Import Activities
        </Button>
        <Button
          type="secondary"
          onClick={() => {
            props.navigateToActivityList({ courseId: props.courseId });
          }}
        >
          Cancel
        </Button>
      </FormSubmitButtons>
      <ProgressModal isOpen={isImporting} />
    </PageContainer>
  );
};

const mapStateToProps = (state: any) => {
  return {
    courseId: state.selected.courseId,
    activitiesToImport: selectActivityIdsToImport(state),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    selectCourse,
    navigateToActivityList,
    importSelectedActivities,
    resetStore,
  })(ActivityImport)
);
