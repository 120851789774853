import { Dialog, DialogContent, DialogTitle, DialogActions } from '@material-ui/core';
import { Button } from 'components/buttons';
import { localize } from 'locales/index';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ArrowLeft from 'images/leftArrow.svg';
import { useState } from 'react';
import { useAdvanceActivityStageMutation } from 'hooks/activity';
import { getActiveStatusName, getNextStatus, translateStageName } from '@kritik/utils/stage';
import { InlineInformation } from 'components/layout';
import { enqueueSnackbar } from 'notistack';
import { trackEvent } from 'utils/userEvents';
import { useAuthUserFromRedux } from 'hooks/user';
import { TranslatedText } from 'components/TranslatedText';
import { Activity, Course } from '@kritik/types.generated';

type AdvanceActivityStageModalProps = {
  activity: Activity;
  course: Course;
  onClose: () => void;
  open: boolean;
};

const AdvanceActivityStageModal = ({
  course,
  activity,
  open,
  onClose,
}: AdvanceActivityStageModalProps) => {
  const activityId = activity._id;
  const authUser = useAuthUserFromRedux();

  const advanceActivityStage = useAdvanceActivityStageMutation(activityId);
  const [error, setError] = useState<string | null>(null);
  const [finished, setFinished] = useState(false);

  const _onClose = () => {
    onClose();
    setTimeout(() => {
      setFinished(false);
    }, 1000);
  };
  const currentStage = getActiveStatusName(activity);
  let nextStage: string = getNextStatus(activity)?.name;
  if (nextStage === 'Processing1') {
    nextStage = 'Evaluate';
  } else if (nextStage === 'Processing2') {
    nextStage = 'Feedback';
  } else if (nextStage === 'Processing3') {
    nextStage = 'Grading';
  }

  const handleConfirm = () => {
    advanceActivityStage.mutate(
      { activityId: activityId },
      {
        onSuccess: () => {
          setFinished(true);
          _onClose();

          trackEvent('Advanced Activity Stage', authUser, {
            instructorEmail: authUser.email,
            courseId: course._id,
            courseName: course.title,
            activityId,
            activityName: activity.title,
            currentStage,
            nextStage,
          });

          enqueueSnackbar(
            localize({
              message: 'Activity.AdvanceStage.Success.Message',
              options: { stageName: nextStage },
            }),
            {
              title: localize({
                message: 'Activity.AdvanceStage.Success.Title',
                options: { stageName: nextStage },
              }),
              variant: 'success',
              testid: 'advance-activity-stage-success',
            }
          );
        },
        onError: ({ response }) => {
          enqueueSnackbar(
            localize({
              message: 'Activity.AdvanceStage.Error.Message',
            }),
            {
              title: localize({
                message: 'Activity.AdvanceStage.Error.Title',
                options: { stageName: nextStage },
              }),
              variant: 'error',
              testid: 'advance-activity-stage-error',
            }
          );

          const errorMessage = response?.data?.errors.message;
          setError(errorMessage);
        },
      }
    );
  };
  return (
    <Dialog
      open={open}
      onClose={_onClose}
      aria-labelledby="advance-activity-dialog-title"
      className="advance-activity-modal"
    >
      {!finished && (
        <div className="advance-activity-modal-container">
          <DialogTitle
            className="dialog-title"
            data-testid="advance-activity-stage-title"
            id="advance-activity-dialog-title"
          >
            <TranslatedText i18nKey="Activity.AdvanceStage.Title" />
          </DialogTitle>
          <DialogContent className="advance-activity-modal-content">
            <div>
              <div className="advance-activity-modal-content_stages-container">
                <div className="stage-from" data-testid={`stage-from-${currentStage}`}>
                  {translateStageName({ stageName: currentStage, activity })}
                </div>
                <div className="arrow-pointer-container">
                  <div
                    className="arrow-icon"
                    style={{ transform: 'translateX(-18px) rotate(180deg)' }}
                  >
                    <img
                      src={ArrowLeft}
                      alt={localize({ message: 'Activity.AdvanceStage.ArrowRight' })}
                    />
                  </div>
                  <div className="check-circle-icon">
                    <CheckCircleIcon />
                  </div>
                </div>
                <div className="stage-to" data-testid={`stage-to-${nextStage}`}>
                  {translateStageName({ stageName: nextStage, activity })}
                </div>
              </div>
            </div>

            {error && (
              <InlineInformation type="danger" style={{ marginTop: '20px' }}>
                {error}
              </InlineInformation>
            )}
            <p>
              <TranslatedText
                i18nKey="Activity.AdvanceStage.Confirm"
                values={{
                  fromStage: translateStageName({ stageName: currentStage, activity }),
                  toStage: translateStageName({ stageName: nextStage, activity }),
                }}
              />
            </p>
            <InlineInformation type="information">
              <TranslatedText i18nKey="Activity.AdvanceStage.Warning" />
            </InlineInformation>
          </DialogContent>
          <DialogActions className="advance-activity-modal-container_button-container">
            <Button
              type="primary"
              inputType="submit"
              data-testid="confirm-advance-activity-stage"
              onClick={() => handleConfirm()}
              disabled={advanceActivityStage.isLoading}
            >
              <TranslatedText i18nKey="Confirm" />
            </Button>
            <Button
              onClick={_onClose}
              type="secondary"
              autoFocus
              data-testid="cancel-advance-activity-stage"
              disabled={advanceActivityStage.isLoading}
            >
              <TranslatedText i18nKey="Cancel" />
            </Button>
          </DialogActions>
        </div>
      )}
    </Dialog>
  );
};

export default AdvanceActivityStageModal;
