import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { selectActivityStatsById } from 'selectors/activity';
import ProgressTooltip from 'components/ActivityCard/Schedule/ProgressTooltip';
import { isPastStatus, isScheduled } from '@kritik/utils/stage';
import InstructorProgressLabels from 'components/AssignmentLabels/InstructorProgressLabels';
import localUtil from 'components/ActivityCard/Schedule/StatusNode/utils';
import { assignmentStatuses as STATUS_LABELS } from '@kritik/constants/stage';
import { useUserRoleInCourse } from 'hooks/course';
import { localize } from 'locales';
import { TranslatedText } from 'components/TranslatedText';

const StatusLabel = (props: any) => {
  const { isStudentInCourse } = useUserRoleInCourse();
  const classes = classNames('status-node-label', {
    'status-node-label--unavailable': !isScheduled(props.activity),
    'status-node-label--past': isPastStatus(props.status, props.activity),
    'status-node-label--active': localUtil.isNodeActive(props.status, props.activity),
    'status-node-label--pending': localUtil.isNodeStartingNext(props.status, props.activity),
  });

  const renderName = () => {
    let _name = props.status.name;
    if (_name === STATUS_LABELS.PROCESSING3) {
      _name = localize({ message: 'StatusLabel.Grading' });
    }
    return <div className="status-node-label__name">{_name}</div>;
  };

  const renderProgress = () => {
    if (
      isStudentInCourse ||
      localUtil.isNodeActive(props.status, props.activity) ||
      !isPastStatus(props.status, props.activity)
    ) {
      return null;
    }
    return (
      <InstructorProgressLabels assignment={props.activity} specificStatus={props.status.name} />
    );
  };

  const isPastStage = isPastStatus(props.status, props.activity);
  const isActiveStage = localUtil.isNodeActive(props.status, props.activity);

  return (
    <ProgressTooltip activity={props.activity} status={props.status}>
      <div className={classes}>
        {renderName()}
        <div className="status-node-label__progress">{renderProgress()}</div>
        <span className="visually-hidden">
          {isPastStage ? (
            <TranslatedText i18nKey="StatusLabel.CompletedStep" />
          ) : isActiveStage ? (
            <TranslatedText i18nKey="StatusLabel.CurrentStep" />
          ) : (
            <TranslatedText i18nKey="StatusLabel.IncompleteStep" />
          )}{' '}
          <TranslatedText i18nKey="StatusLabel.Step" />
        </span>
      </div>
    </ProgressTooltip>
  );
};

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    stats: selectActivityStatsById(state, ownProps.activity._id),
  };
};

export default connect(mapStateToProps)(StatusLabel);
