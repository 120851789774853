import { normalize } from 'normalizr';
import { push, replace } from 'router';
import { reset, SubmissionError } from 'redux-form';
import { mergeEntities, syncEntities, updateEntities, replaceEntities } from 'actions/entities';
import { assignmentSchema, creationSchema, courseSchema } from 'schemas';
import { assignmentService, creationService, evaluationService, adminService } from 'services';
import * as types from 'types';
import CreationTypes from 'actions/creation/types';
import paginationTypes from 'actions/pagination/types';
import { listRubrics } from 'actions/rubrics';
import StudentActions from 'actions/students';
import { normalizeEvaluations, selectEvaluation } from 'redux/evaluation';
import { saveSchedulingTemplate } from 'redux/schedule/actions';
import { openLoadingModal, closeLoadingModal, openErrorModal } from 'actions/modals';
import { setStudentCreationMap, setStudentFeedbackReceivedMap } from 'redux/creation';
import { trackEvent } from 'utils/userEvents';
import ActivityTypes from 'redux/activity/types';
import { resetUploadStatus } from 'redux/upload';
import UserSelectors, { selectCurrentInstructor } from 'selectors/user';
import ActivitySelectors, { selectCurrentActivity } from 'selectors/activity';
import CourseSelectors, { selectCurrentCourse } from 'selectors/course';
import CreationSelectors from 'selectors/creation';
import { setTotalRowsCount, setPageCount } from 'redux/creationTable/actions';
import { selectPageSize } from 'redux/creationTable/select';
import { getPageCount } from 'components/CreationList/PaginationMenu/utils';
import getCreationTableQueryParams from 'actions/pagination/utils';
import * as ErrorUtils from 'utils/error';
import { isPresentationActivity } from '@kritik/utils/activity';
import { getCourse } from 'selectors/course';
import * as modalTypes from 'types/modal';

import { showLogin } from './users';
import { localize } from 'locales/index';
import { Creation } from 'old-common/types.generated';

// actions
export function navigateToActivityList({ courseId }: any) {
  return push(`/course/${courseId}/assignments`);
}

export function navigateToEditActivity({ courseId, activityId }: any) {
  return push(`/course/${courseId}/assignment/${activityId}/edit-assignment`);
}

export function navigateToEditActivityParticipation({ courseId, activityId }: any) {
  return replace(`/course/${courseId}/assignment/${activityId}/edit-participation`);
}

export function navigateToActivitySchedule({
  courseId,
  activity,
}: {
  courseId: string;
  activity: any;
}) {
  if (isPresentationActivity(activity)) {
    return push(`/course/${courseId}/assignment/${activity._id}/presentation-schedule`);
  }
  return push(`/course/${courseId}/assignment/${activity._id}/schedule`);
}

export function navigateToDuplicateActivity({ courseId, activityId }: any) {
  return push(`/course/${courseId}/assignment/${activityId}/duplicate-assignment`);
}

function constructUrlQueryStringFromStateParams({ state }: any) {
  const urlParams = getCreationTableQueryParams(state);
  delete urlParams.activityId;
  // @ts-expect-error TS(2345) FIXME: Argument of type '{ resultsPerPage: any; page: any... Remove this comment to see the full error message
  return new URLSearchParams(urlParams).toString();
}

type NavigateToActivityPageParams = {
  courseId: string;
  assignmentId: string;
  showRevertActivityModal?: boolean;
  showAdvanceActivityStageModal?: boolean;
};
export function navigateToActivityPage({
  courseId,
  assignmentId,
  showRevertActivityModal,
  showAdvanceActivityStageModal,
}: NavigateToActivityPageParams) {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const course = getCourse(state, courseId);
    trackEvent('Activity Opened', state.user.authUser, {
      isDemoCourse: Boolean(state.course?.courseSettings?.isDemoCourse),
      activityId: assignmentId,
    });
    if (course.userRole === 'student') {
      return dispatch(push(`/course/${courseId}/assignment/${assignmentId}`));
    }
    const paginationQueryParams = constructUrlQueryStringFromStateParams({
      state,
    });
    return dispatch(
      push(
        `/course/${courseId}/assignment/${assignmentId}?${paginationQueryParams}${
          showRevertActivityModal ? `&revert=true` : ''
        }${showAdvanceActivityStageModal ? `&advance=true` : ''}`
      )
    );
  };
}

type NavigateToActivityPageScoreComparisonParams = {
  courseId: string;
  assignmentId: string;
  showRevertActivityModal?: boolean;
  showAdvanceActivityStageModal?: boolean;
};
export function navigateToActivityPageScoreComparison({
  courseId,
  assignmentId,
}: NavigateToActivityPageScoreComparisonParams) {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const course = getCourse(state, courseId);
    trackEvent('Activity Opened', state.user.authUser, {
      isDemoCourse: Boolean(state.course?.courseSettings?.isDemoCourse),
      activityId: assignmentId,
    });
    if (course.userRole === 'student') {
      return dispatch(push(`/course/${courseId}/assignment/${assignmentId}`));
    }
    const paginationQueryParams = constructUrlQueryStringFromStateParams({
      state,
    });
    return dispatch(
      push(
        `/course/${courseId}/assignment/${assignmentId}/score-comparison?${paginationQueryParams}`
      )
    );
  };
}

export function navigateToInstructorCreationView({
  assignmentId,
  courseId,
  creationId,
  groupView,
}: any) {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const paginationQueryParams = constructUrlQueryStringFromStateParams({
      state,
    });
    return dispatch(
      push(
        `/course/${courseId}/assignment/${assignmentId}/creation/${creationId}?groupView=${!!groupView}&${paginationQueryParams}`
      )
    );
  };
}

export function navigateToActivityPath(path: any) {
  return push(path);
}

export const createAssignmentSuccess = (payload: any) => {
  return {
    type: types.CREATE_ASSIGNMENT_SUCCESS,
    payload,
  };
};

export function createAssignmentSubmission(data: any) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: 'CREATE_ASSIGNMENT_SUBMISSION_REQUESTS' });

    try {
      const state = getState();
      const authUser = UserSelectors.selectAuthUser(state);
      const isDemoCourse = Boolean(state.course?.courseSettings?.isDemoCourse);
      const instructor = UserSelectors.selectCurrentInstructor(state);

      const activity = ActivitySelectors.selectCurrentActivity(state);

      const attachments = {};
      data.files.forEach((file: any) => {
        attachments[file.name] = `${file.size / 1000} KB`; // to KB
      });

      trackEvent('Creation Created', authUser, {
        studentEmail: authUser.email,
        instructorEmail: instructor.email,
        activityId: activity._id,
        activityType: activity.type,
        createdAt: new Date().toISOString(),
        attachments: JSON.stringify(attachments),
        isDemoCourse,
      });
    } catch (error) {
      // do nothing for now
    }

    return assignmentService()
      .createSubmission({ assignmentId: data.assignment, data })
      .then((res: any) => {
        if (res.status === 200) {
          dispatch(reset('assignment_submission'));
          const normalized = normalize(res.data, creationSchema);
          dispatch(updateEntities(normalized.entities));

          const assignment =
            getState().entities.assignments && getState().entities.assignments[data.assignment];
          if (assignment && res.data._id) {
            assignment.userAssignments = [res.data._id];
          }
          dispatch(syncEntities(assignment, 'assignments'));
          dispatch({ type: 'CREATE_ASSIGNMENT_SUBMISSION_SUCCESS', payload: res.data });
          dispatch(resetUploadStatus());
        }
      })
      .catch((err: any) => {
        dispatch({ type: 'CREATE_ASSIGNMENT_SUBMISSION_FAILURE', payload: err });
        throw new Error(ErrorUtils.getErrorMessageFromResponse(err));
      });
  };
}

export function evaluateAssignmentSubmission(data: any) {
  return (dispatch: any, getState: any) => {
    try {
      const state = getState();
      const authUser = UserSelectors.selectAuthUser(state);
      const instructor = UserSelectors.selectCurrentInstructor(state);

      const activity = ActivitySelectors.selectCurrentActivity(state);
      const course = CourseSelectors.selectCurrentCourse(state);
      const creationToEval = CreationSelectors.getCreationByActivityAndUser(
        state,
        data.assignment._id,
        data.user
      );

      const creationAttachments = {};
      creationToEval.files.forEach((file: any) => {
        creationAttachments[file.name] = `${file.size / 1000} KB`; // to KB
      });
      const evaluationAttachments = {};
      data.files.forEach((file: any) => {
        evaluationAttachments[file.name] = `${file.size / 1000} KB`; // to KB
      });
      trackEvent('Evaluation Created', authUser, {
        studentEmail: authUser.email,
        instructorEmail: instructor.email,
        activityId: activity._id,
        activityName: activity.title,
        activityType: activity.type,
        createdAt: new Date().toISOString(),
        creationToEval: creationToEval._id,
        creationAttachments: JSON.stringify(creationAttachments),
        evaluationAttachments: JSON.stringify(evaluationAttachments),
        highlightedCriteria: data.highlightedCriteria,
        marks: data.marks,
        courseId: course._id,
        courseName: course.title,
        isDemoCourse: course.courseSettings.isDemoCourse,
      });
    } catch (error) {
      // do nothing for now
    }
    if (!getState().user.authenticated) {
      return dispatch(showLogin({ redirect: location.pathname }));
    }

    dispatch({ type: 'CREATE_SUBMISSION_EVALUATION_REQUEST' });
    let actionPromise;
    if (!data.isAdminPanel) {
      if (!data.evaluationId) {
        //student is creating a new evaluation
        actionPromise = () =>
          evaluationService().createEvaluation({
            comment: data.comment,
            creationId: data.creationId,
            files: data.files,
            highlightedCriteria: data.highlightedCriteria,
            marks: data.marks,
            lateEvaluationReason: data.lateEvaluation?.reason,
          });
      } else {
        //student is updating an evaluation
        actionPromise = () =>
          evaluationService().updateEvaluation({
            comment: data.comment,
            evaluationId: data.evaluationId,
            files: data.files,
            highlightedCriteria: data.highlightedCriteria,
            marks: data.marks,
            lateEvaluationReason: data.lateEvaluation?.reason,
          });
      }
    } else {
      //admin is creating a new evaluation
      if (!data.evaluationId) {
        actionPromise = () =>
          adminService().createEvaluation({
            evaluatorUserId: data.evalUser,
            comment: data.comment,
            creationId: data.creationId,
            files: data.files,
            highlightedCriteria: data.highlightedCriteria,
            marks: data.marks,
            lateEvaluationReason: data.lateEvaluation?.reason,
            notifications: data.notifications,
          });
      } else {
        //admin is updating an evaluation
        actionPromise = () =>
          adminService().updateEvaluation({
            comment: data.comment,
            evaluationId: data.evaluationId,
            files: data.files,
            highlightedCriteria: data.highlightedCriteria,
            marks: data.marks,
            notifications: data.notifications,
          });
      }
    }

    return actionPromise()
      .then((res: any) => {
        if (res.status === 200) {
          const normalized = normalize(res.data, creationSchema);
          dispatch(updateEntities(normalized.entities));
          dispatch({ type: 'CREATE_SUBMISSION_EVALUATION_SUCCESS', payload: res.data });
        }
      })
      .catch((err: any) => {
        dispatch({ type: 'CREATE_SUBMISSION_EVALUATION_FAILURE', payload: err });
        throw new SubmissionError(
          (err && err.response && err.response.data && err.response.data.errors) || {}
        );
      });
  };
}

export function updateAssignmentSubmission(data: any) {
  return (dispatch: any, getState: any) => {
    if (!getState().user.authenticated) {
      return dispatch(showLogin({ redirect: location.pathname }));
    }
    const state = getState();
    const selectedCourseId: string = state.selected.courseId;
    const course = getCourse(state, selectedCourseId);
    const isInstructorInCourse = course.userRole === 'instructor';
    dispatch({ type: types.UPDATE_SUBMISSION_EVALUATION_REQUEST });
    return creationService()
      .update({ creationId: data.submissionId, ...data })
      .then((res: any) => {
        if (res.status === 200) {
          const { creation, assignment } = res.data;
          const normalizedSubmission = normalize(creation, creationSchema);
          dispatch(mergeEntities(normalizedSubmission.entities));

          const normalizedAssignment = normalize(assignment, assignmentSchema);
          dispatch(mergeEntities(normalizedAssignment.entities));
          dispatch({ type: types.UPDATE_SUBMISSION_EVALUATION_SUCCESS, payload: res.data });
          if (isInstructorInCourse) {
            dispatch(StudentActions.getStudentsByCourse({ courseId: selectedCourseId }));
          }
        }
      })
      .catch((err: any) => {
        dispatch({ type: types.UPDATE_SUBMISSION_EVALUATION_FAILURE, payload: err });
        throw new SubmissionError(
          (err && err.response && err.response.data && err.response.data.errors) || {}
        );
      });
  };
}

export function waiveLateSubmissionRight({ creationId, assignmentId }: any) {
  return async (dispatch: any, getState: any) => {
    await dispatch(updateAssignmentSubmission({ creationId, lockCreationContent: true }));
    dispatch(getAssignmentSubmissions({ assignmentId }));
  };
}

export function approveAssignmentSubmissionEvaluation(data: any) {
  return (dispatch: any, getState: any) => {
    if (!getState().user.authenticated) {
      return dispatch(showLogin({ redirect: location.pathname }));
    }

    dispatch({ type: 'APPROVE_SUBMISSION_EVALUATION_REQUEST' });

    return assignmentService()
      .approveSubmissionEvaluation(data)
      .then((res: any) => {
        if (res.status === 200) {
          const normalized = normalize(res.data, creationSchema);
          dispatch(mergeEntities(normalized.entities));
          dispatch({ type: 'APPROVE_SUBMISSION_EVALUATION_SUCCESS', payload: res.data });
        }
      })
      .catch((err: any) => {
        dispatch({ type: 'APPROVE_SUBMISSION_EVALUATION_FAILURE', payload: err });
        throw new SubmissionError(
          (err && err.response && err.response.data && err.response.data.errors) || {}
        );
      });
  };
}

export function commentAssignmentSubmissionEvaluation(data: any) {
  return (dispatch: any, getState: any) => {
    if (!getState().user.authenticated) {
      return dispatch(showLogin({ redirect: location.pathname }));
    }

    dispatch({ type: 'COMMENT_SUBMISSION_EVALUATION_REQUEST' });

    return assignmentService()
      .commentSubmissionEvaluation({
        assignmentId: data.assignmentId,
        evaluatorId: data.evaluatorId,
        submitterId: data.submitterId,
        data,
      })
      .then((res: any) => {
        if (res.status === 200) {
          const normalized = normalize(res.data, creationSchema);
          dispatch(updateEntities(normalized.entities));
          dispatch({ type: 'COMMENT_SUBMISSION_EVALUATION_SUCCESS', payload: res.data });
          dispatch({ type: CreationTypes.GET_CREATIONS_TO_EVALUATE_SUCCESS, payload: [res.data] });
        }
      })
      .catch((err: any) => {
        dispatch({ type: 'COMMENT_SUBMISSION_EVALUATION_FAILURE', payload: err });
        throw new SubmissionError(
          (err && err.response && err.response.data && err.response.data.errors) || {}
        );
      });
  };
}

export function createEvaluationFof(data: any) {
  return (dispatch: any, getState: any) => {
    try {
      const state = getState();
      const authUser = state.user.authUser;
      const activity = selectCurrentActivity(state);
      const course = selectCurrentCourse(state);
      const instructor = selectCurrentInstructor(state);
      const evaluation = selectEvaluation(state, data.scoreId);

      const evaluationAttachments = {};
      evaluation.files.forEach((file: any) => {
        evaluationAttachments[file.name] = `${file.size / 1000} KB`; // to KB
      });
      trackEvent('Feedback Created', authUser, {
        studentEmail: authUser.email,
        instructorEmail: instructor.email,
        activityId: activity._id,
        activityName: activity.title,
        activityType: activity.type,
        createdAt: new Date().toISOString(),
        evaluationAttachments: JSON.stringify(evaluationAttachments),
        courseId: course._id,
        courseName: course.title,
        critical: data.feedbackOnFeedback.critical,
        motivational: data.feedbackOnFeedback.motivational,
        isDemoCourse: course.courseSettings.isDemoCourse,
      });
    } catch (error) {
      console.log(error);
      // do nothing for now
    }
    if (!getState().user.authenticated) {
      return dispatch(showLogin({ redirect: location.pathname }));
    }

    dispatch({ type: types.CREATE_EVALUATION_FOF_REQUEST });
    return assignmentService()
      .createEvaluationFof({ submissionId: data.submissionId, scoreId: data.scoreId, data })
      .then((res: any) => {
        if (res.status === 200) {
          const normalized = normalize(res.data, creationSchema);
          dispatch(mergeEntities(normalized.entities));
          dispatch({ type: types.CREATE_EVALUATION_FOF_SUCCESS, payload: res.data });
        }
      })
      .catch((err: any) => {
        dispatch({ type: types.CREATE_EVALUATION_FOF_FAILURE, payload: err });
        if (err?.response?.data?.errors?.message) {
          throw new Error(err.response.data.errors.message);
        }
        throw new SubmissionError(err?.response?.data?.error || {});
      });
  };
}

export function createEvaluationDispute(data: any) {
  return (dispatch: any, getState: any) => {
    const state = getState();
    const course = selectCurrentCourse(state);
    if (!state.user.authenticated) {
      return dispatch(showLogin({ redirect: location.pathname }));
    }

    try {
      const authUser = state.user.authUser;
      if (course.userRole === 'student') {
        const activity = selectCurrentActivity(state);
        const course = selectCurrentCourse(state);
        const instructor = selectCurrentInstructor(state);
        trackEvent('Grade Disputed', authUser, {
          studentEmail: authUser.email,
          instructorEmail: instructor.email,
          activityId: activity._id,
          activityName: activity.title,
          activityType: activity.type,
          createdAt: new Date().toISOString(),
          courseId: course._id,
          courseName: course.title,
          disputeStatus: data.dispute.status,
          disputeReason: data.dispute.reason,
        });
      }
    } catch (error) {
      // do nothing for now
    }
    dispatch({ type: 'CREATE_EVALUATION_DISPUTE_REQUEST' });

    return assignmentService()
      .createEvaluationDispute({ submissionId: data.submissionId, data })
      .then((res: any) => {
        if (res.status === 200) {
          const normalized = normalize(res.data, creationSchema);
          dispatch(mergeEntities(normalized.entities));
          dispatch({ type: 'CREATE_EVALUATION_DISPUTE_SUCCESS', payload: res.data });
        }
      })
      .catch((err: any) => {
        dispatch({ type: 'CREATE_EVALUATION_DISPUTE_FAILURE', payload: err });
        throw err;
      });
  };
}

export function createEvaluationFlag(data: any) {
  return (dispatch: any, getState: any) => {
    try {
      const state = getState();
      const authUser = state.user.authUser;
      const activity = selectCurrentActivity(state);
      const course = selectCurrentCourse(state);
      const instructor = selectCurrentInstructor(state);

      if (course.userRole === 'student') {
        trackEvent('Evaluation Flagged', authUser, {
          studentEmail: authUser.email,
          instructorEmail: instructor.email,
          activityId: activity._id,
          activityName: activity.title,
          activityType: activity.type,
          createdAt: new Date().toISOString(),
          evaluationId: data.scoreId,
          courseId: course._id,
          courseName: course.title,
          flag: data.flag,
        });
      }
    } catch (error) {
      // do nothing for now
    }

    dispatch({ type: 'CREATE_EVALUATION_FLAG_REQUEST' });

    return assignmentService()
      .createEvaluationFlag({ submissionId: data.submissionId, scoreId: data.scoreId, data })
      .then((res: any) => {
        if (res.status === 200) {
          const { submission, assignment } = res.data;

          const normalizedSubmission = normalize(submission, creationSchema);
          dispatch(mergeEntities(normalizedSubmission.entities));

          const normalizedAssignment = normalize(assignment, assignmentSchema);
          dispatch(mergeEntities(normalizedAssignment.entities));
          dispatch({ type: 'CREATE_EVALUATION_FLAG_SUCCESS', payload: res.data });
        }
      })
      .catch((err: any) => {
        dispatch({ type: 'CREATE_EVALUATION_FLAG_FAILURE', payload: err });
        throw err;
      });
  };
}

export function getAssignmentSubmissions(params = {}, { callback, client }: any = {}) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: types.GET_ASSIGNMENT_SUBMISSIONS_REQUEST, payload: { params } });
    return assignmentService({ client })
      .getSubmissions(params)
      .then((res: any) => {
        if (res.status === 200) {
          const normalized = normalize(res.data, [creationSchema]);
          dispatch(mergeEntities(normalized.entities));
          dispatch({
            type: 'GET_ASSIGNMENT_SUBMISSIONS_SUCCESS',
            payload: { params, items: normalized.result },
          });
          const normalizedCreations = Object.values(normalized.entities.user_assignments || {});
          dispatch(setTotalRowsCount(normalizedCreations.length));
          dispatch(setStudentCreationMap(normalizedCreations));
          dispatch(setStudentFeedbackReceivedMap(normalizedCreations));
          if (typeof callback === 'function') {
            callback(res.data);
          }
          dispatch(normalizeEvaluations(normalizedCreations));
        }
      })
      .catch((err: any) => {
        dispatch({ type: types.GET_ASSIGNMENT_SUBMISSIONS_FAILURE, payload: err });
        if (typeof callback === 'function') {
          callback([]);
        }
      });
  };
}

export function listCreationsByActivity(params = {}, { callback, client }: any = {}) {
  return (dispatch: any, getState: any) => {
    dispatch({
      type: paginationTypes.GET_ACTIVITY_PAGINATED_CREATIONS_REQUEST,
      payload: { params },
    });
    return assignmentService({ client })
      .listCreationsByActivity(params)
      .then((res: any) => {
        if (res.status === 200) {
          const state = getState();
          const normalized = normalize(res.data.creations, [creationSchema]);
          const { numResults, feedbackReceivedMap } = res.data;
          dispatch(setTotalRowsCount(numResults));
          const pageSize = selectPageSize(state);
          dispatch(setPageCount(getPageCount(numResults, pageSize)));
          dispatch(
            replaceEntities({ user_assignments: normalized.entities.user_assignments || {} })
          );
          dispatch(replaceEntities({ groups: normalized.entities.groups }));
          const normalizedCreations = Object.values(normalized.entities.user_assignments || {});
          dispatch(setStudentCreationMap(normalizedCreations));
          dispatch({
            type: paginationTypes.SET_STUDENT_FEEDBACK_RECEIVED_MAP,
            payload: {
              studentFeedbackReceivedMap: feedbackReceivedMap,
            },
          });
          if (typeof callback === 'function') {
            callback(res.data);
          }
          dispatch(normalizeEvaluations(normalizedCreations));
          dispatch({ type: paginationTypes.GET_ACTIVITY_PAGINATED_CREATIONS_SUCCESS });
        }
      })
      .catch((err: any) => {
        dispatch({ type: paginationTypes.GET_ACTIVITY_PAGINATED_CREATIONS_FAILURE, payload: err });
        if (typeof callback === 'function') {
          callback([]);
        }
      });
  };
}

export function getAssignment(params = {}, { client, callback, reject }: any = {}) {
  return (dispatch: any) => {
    dispatch({ type: ActivityTypes.GET_ASSIGNMENT, payload: (params as any).id });

    return assignmentService({ client })
      .get(params)
      .then((res: any) => {
        const normalized = normalize(res.data, assignmentSchema);
        dispatch(updateEntities(normalized.entities));
        dispatch({ type: ActivityTypes.GET_ASSIGNMENT_SUCCESS });
        if (typeof callback === 'function') {
          callback(res.data);
        }
      })
      .catch((err: any) => {
        dispatch({ type: ActivityTypes.GET_ASSIGNMENT_FAILURE, error: err });
      });
  };
}

export function getAssignments(params = {}, { callback, client }: any = {}) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: types.GET_ASSIGNMENTS_REQUEST, payload: { params } });
    return assignmentService({ client })
      .list(params)
      .then((res: any) => {
        if (res.status === 200) {
          const normalized = normalize(res.data, [assignmentSchema]);
          dispatch(mergeEntities(normalized.entities));

          dispatch({
            type: 'GET_ASSIGNMENTS_SUCCESS',
            payload: { params, items: normalized.result },
          });
          if (typeof callback === 'function') {
            callback(res.data);
          }
          return res.data;
        }
      })
      .catch((err: any) => {
        dispatch({ type: types.GET_ASSIGNMENTS_FAILURE, payload: err });
        if (typeof callback === 'function') {
          callback([]);
        }
      });
  };
}

export function updateAssignment(data: any) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: 'UPDATE_ASSIGNMENT_REQUEST' });
    return assignmentService()
      .update({ id: data._id, data })
      .then((res: any) => {
        if (res.status === 200) {
          const normalized = normalize(res.data, assignmentSchema);
          dispatch(updateEntities(normalized.entities));
          dispatch({ type: 'UPDATE_ASSIGNMENT_SUCCESS', payload: res.data });
          dispatch(listRubrics({ courseId: data.course }));
        }
      });
  };
}

export function updateAssignmentSchedule(data: any) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: types.UPDATE_ASSIGNMENT_SCHEDULE });
    return assignmentService()
      .updateSchedule({ id: data._id, data })
      .then((res: any) => {
        if (res.status === 200) {
          const normalized = {
            statuses: {},
          };

          res.data.forEach((status: any) => {
            normalized.statuses[status._id] = status;
          });
          trackEvent('Activity Scheduled', getState().user.authUser, {
            activityType: data.activityType,
            activityId: data.assignmentId,
            ...activityScheduledEventProps(res.data),
          });

          dispatch(mergeEntities(normalized));
          dispatch({ type: types.UPDATE_ASSIGNMENT_SCHEDULE_SUCCESS, success: true });
        }
      })
      .then(() => {
        setTimeout(() => {
          dispatch({ type: types.RESET_ASYNC, payload: types.UPDATE_ASSIGNMENT_SCHEDULE });
        }, 2000);
        dispatch(saveSchedulingTemplate());
      })
      .catch((err: any) => {
        dispatch({ type: types.UPDATE_ASSIGNMENT_SCHEDULE_FAILURE, error: true });
        throw new SubmissionError(err?.response?.data?.errors?.message);
      });
  };
}

function activityScheduledEventProps(data: any) {
  const oneDay = 24 * 60 * 60 * 1000;
  let create = 0;
  let evaluate = 0;
  let feedback = 0;
  const calcStageLength = (stage: any) => {
    // @ts-expect-error TS(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
    return Math.ceil(Math.abs(new Date(stage.endDate) - new Date(stage.startDate)) / oneDay);
  };
  const statuses = data.statusList ? data.statusList : data;
  for (const stage of statuses) {
    if (stage.name === 'Create' && stage.startDate !== null) {
      create = calcStageLength(stage);
    }
    if (stage.name === 'Feedback' && stage.startDate !== null) {
      feedback = calcStageLength(stage);
    }
    if (stage.name === 'Evaluate' && stage.startDate !== null) {
      evaluate = calcStageLength(stage);
    }
  }

  return {
    createDays: create,
    evaluateDays: evaluate,
    feedbackDays: feedback,
  };
}

export function finalizeAssignment(assignmentId: any, courseId: any) {
  return async (dispatch: any, getState: any) => {
    try {
      dispatch({ type: ActivityTypes.FINALIZE_ASSIGNMENT_REQUEST });
      dispatch(
        openLoadingModal({
          title: 'Please Wait',
          content: 'Finalizing activity.........',
        })
      );
      const res = await assignmentService().finalize(assignmentId);
      if (res.status === 200) {
        const finalizedAssignment = res.data;
        const normalized = normalize(finalizedAssignment, assignmentSchema);
        dispatch(updateEntities(normalized.entities));
        dispatch({ type: ActivityTypes.FINALIZE_ASSIGNMENT_SUCCESS });
        dispatch(closeLoadingModal());
      }
    } catch (error) {
      dispatch({ type: ActivityTypes.FINALIZE_ASSIGNMENT_FAILURE });
      dispatch(closeLoadingModal());
      dispatch(
        openErrorModal({
          title: localize({ message: 'Error.SomethingWrongHappened' }),
          content:
            error.response?.data?.errors?.message ||
            localize({ message: 'Error.ErrorBoundaryText2' }),
          handleClose: () => {
            return dispatch({ type: modalTypes.CLOSE_ERROR_MODAL });
          },
        })
      );
    }
  };
}

export function deleteAssignment({ id = null, courseId }: any) {
  return (dispatch: any, getState: any) => {
    return assignmentService()
      .delete({ id })
      .then((res: any) => {
        if (res.status === 200) {
          return dispatch(push(`/course/${courseId}/assignments`));
        }
      })
      .catch((err: any) => {
        throw new SubmissionError(
          (err && err.response && err.response.data && err.response.data.errors) || {}
        );
      });
  };
}

export function deleteFinalizedActivity({ id = null, courseId }: any) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: types.DELETE_FINALIZED_REQUEST });
    return assignmentService()
      .deleteFinalized({ id, courseId })
      .then((res: any) => {
        if (res.status === 200) {
          const { activities } = res.data;
          const { course } = res.data;
          const normalizedCourse = normalize(course, courseSchema);
          const normalizedActivities = normalize(activities, [assignmentSchema]);

          dispatch(updateEntities(normalizedActivities.entities));
          dispatch(updateEntities(normalizedCourse.entities));

          dispatch({ type: types.DELETE_FINALIZED_SUCCESS });
          return dispatch(push(`/course/${courseId}/assignments`));
        }
      })
      .catch((err: any) => {
        dispatch({ type: types.DELETE_FINALIZED_FAILURE, payload: err });
        throw new SubmissionError(
          (err && err.response && err.response.data && err.response.data.errors) || {}
        );
      });
  };
}

export function resolveDispute(data: any) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: types.RESOLVE_DISPUTE_REQUEST });
    return assignmentService()
      .resolveDispute(data)
      .then((res: any) => {
        if (res.status === 200) {
          const { submission, assignment } = res.data;

          const normalizedSubmission = normalize(submission, creationSchema);
          dispatch(mergeEntities(normalizedSubmission.entities));

          const normalizedAssignment = normalize(assignment, assignmentSchema);
          dispatch(mergeEntities(normalizedAssignment.entities));

          dispatch({ type: types.RESOLVE_DISPUTE_SUCCESS });
        }
      })
      .catch((err: any) => {
        dispatch({ type: types.RESOLVE_DISPUTE_FAILURE });
      });
  };
}

export function resolveLateSubmission(data: any) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: types.RESOLVE_LATE_SUBMISSION_REQUEST });
    return assignmentService()
      .resolveLateSubmission(data)
      .then((res: any) => {
        if (res.status === 200) {
          const { submission, assignment } = res.data;

          const normalizedSubmission = normalize(submission, creationSchema);
          dispatch(mergeEntities(normalizedSubmission.entities));

          const normalizedAssignment = normalize(assignment, assignmentSchema);
          dispatch(mergeEntities(normalizedAssignment.entities));

          dispatch({ type: types.RESOLVE_LATE_SUBMISSION_SUCCESS });
        }
      })
      .catch((err: any) => {
        dispatch({ type: types.RESOLVE_LATE_SUBMISSION_FAILURE });
      });
  };
}

export function createGroupActivitySubmissions(data: any) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: 'CREATE_GROUP_ACTIVITY_SUBMISSIONS' });
    return assignmentService()
      .createGroupActivitySubmissions(data)
      .then((res: any) => {
        if (res.status === 200) {
          dispatch({ type: 'CREATE_GROUP_ACTIVITY_SUBMISSIONS_SUCCESS' });
        }
      })
      .catch((err: any) => {
        dispatch({ type: 'CREATE_GROUP_ACTIVITY_SUBMISSIONS_FAILURE', payload: err });
        return err;
      });
  };
}

export function getActivityStatsByCourse({ courseId }: any) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: types.GET_ACTIVITY_STATS });
    return assignmentService()
      .getActivityStats({ courseId })
      .then((res: any) => {
        if (res.status === 200) {
          dispatch({ type: types.GET_ACTIVITY_STATS_SUCCESS, payload: res.data });
        }
      })
      .catch((err: any) => {
        dispatch({ type: types.GET_ACTIVITY_STATS_FAILURE, payload: err });
        return err;
      });
  };
}

export function updateActivityWeight({ activityId, weight }: any) {
  return (dispatch: any, getState: any) => {
    dispatch({ type: types.UPDATE_ASSIGNMENT_REQUEST });
    return assignmentService()
      .updateWeight({ activityId, weight })
      .then((res: any) => {
        if (res.status === 200) {
          const normalizedActivity = normalize(res.data, assignmentSchema);
          dispatch(mergeEntities(normalizedActivity.entities));
          dispatch({ type: types.UPDATE_ASSIGNMENT_SUCCESS, payload: res.data });
        }
      })
      .catch((err: any) => {
        dispatch({ type: types.UPDATE_ASSIGNMENT_FAILURE, payload: err });
      });
  };
}

export function getActivityValidRevertStage({ activityId }: any) {
  return async () => {
    try {
      const res = await assignmentService().getActivityValidRevertStage({ activityId });
      return res;
    } catch (err) {
      const error = ErrorUtils.getErrorMessageFromResponse((err as any).response);
      return { error: (error as any).message || error };
    }
  };
}

export function revertActivity({ activityId, revertTo }: any) {
  return async () => {
    try {
      const res = await assignmentService().revertActivity({ activityId, revertTo });
      return res;
    } catch (err) {
      const error = ErrorUtils.getErrorMessageFromResponse((err as any).response);
      return { error: (error as any).message || error };
    }
  };
}

export function updateActivityInReduxStore(dispatch: any, data: Creation) {
  const normalized = normalize(data, assignmentSchema);
  dispatch(updateEntities(normalized.entities));
}
