import { useState } from 'react';
import { connect } from 'react-redux';
import {
  SignupContainer,
  SignupMainTitle,
  SignupContent,
  SignupText,
} from 'components/AuthWrapper';
import Button from 'components/buttons/Button';
import FormTextInput from 'components/core/form/TextField';
import { Form } from 'components/Form';
import { userService } from 'services';
import isEmail from 'validator/lib/isEmail';
import * as GeneralUtils from '@kritik/utils/general';
import * as ErrorUtils from 'utils/error';
import Modal from 'components/modals/Components/Modal';
import logo from 'images/logo-small.png';
import NavigationLink from 'components/Navigation/Link';
import Grid from 'images/icons/svg/Grid';
import HelpTab from 'components/Navigation/Tabs/Help';
import { push } from 'router';
import ArrowRight from 'images/icons/svg/ArrowRight';
import { trackEvent } from 'utils/userEvents';
import { withRouter } from 'utils/withRouter';
import { AuthUser } from 'app-types';
import { isForbiddenEmailForRegister } from '@kritik/utils/email';
import { localize } from 'locales/index';
import { TranslatedText } from 'components/TranslatedText';

type Props = {
  user: {
    authUser: AuthUser;
    authenticated: boolean;
  };
  router: any;
};

const DemoRegister = ({ user, ...props }: Props) => {
  const [email, setEmail] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const requireEmail =
    !user?.authenticated || (user && !user.authUser.permissions?.includes('canCreateCourse'));

  const handleInputChange = (e: any) => {
    setError(null);
    setLoading(false);
    const _email = GeneralUtils.stripWhiteSpaces(e.target.value);
    setEmail(_email);
  };

  const handleOnSubmit = async () => {
    if (requireEmail && !isEmail(email)) {
      return setError(localize({ message: 'DemoRegister.Error.NotValidEmail' }));
    }

    if (isForbiddenEmailForRegister({ email })) {
      return setError(localize({ message: 'DemoRegister.Error.EnterFacultyEmail' }));
    }

    setLoading(true);
    try {
      const emailInput = requireEmail ? email : user.authUser.email;
      const response = await userService().registerAsDemoStudent({ email: emailInput });
      trackEvent('Enter Student Experience', user?.authUser, {
        instructorEmail: email,
      });
      if (response?.data?.demoCourseId) {
        document.location.href = `/course/${response.data.demoCourseId}/assignments`;
        return;
      }
      document.location.href = '/';
    } catch (err) {
      const _error = ErrorUtils.getErrorMessageFromResponse(err);
      setError(_error);
      setLoading(false);
    }
  };

  return (
    <>
      <FakeProduct />
      <Modal isOpen={true}>
        <SignupContainer className="demo-container" graphic="classroom">
          <SignupContent>
            <SignupMainTitle>
              <TranslatedText i18nKey="DemoRegister.WelcomeTitle" />
            </SignupMainTitle>
            <SignupText>
              <TranslatedText i18nKey="DemoRegister.WelcomeDescription" />
            </SignupText>
            <Form>
              {requireEmail && (
                <FormTextInput
                  testid="email"
                  name="email"
                  label={localize({ message: 'DemoRegister.FacultyEmailLabel' })}
                  value={email}
                  onChange={handleInputChange}
                  error={error}
                />
              )}
              <Button type="primary" onClick={handleOnSubmit} loading={loading}>
                <TranslatedText i18nKey="DemoRegister.TakeTourButton" />
              </Button>
            </Form>
            {requireEmail && (
              <div className="demo-sign-up-container">
                <SignupText>
                  <TranslatedText i18nKey="DemoRegister.ExploreDescription" />
                </SignupText>
                <Button
                  type="primary"
                  onClick={() => {
                    props.router.push('/signup/instructor');
                  }}
                  inputType="submit"
                >
                  <TranslatedText i18nKey="DemoRegister.SignUpButton" />
                  <ArrowRight />
                </Button>
              </div>
            )}
          </SignupContent>
        </SignupContainer>
      </Modal>
    </>
  );
};

const FakeProduct = () => {
  return (
    <div className="main-wrapper">
      <div className="NavigationBar">
        <div className="header-logo">
          <img src={logo} alt={localize({ message: 'Navigation.DemoRegisterPage.Logo' })} />
        </div>
        <NavigationLink
          to="/"
          selectable
          icon={<Grid width={18} height={18} />}
          label={localize({ message: 'DemoRegister.AllCoursesLink' })}
        />
        <div className="bottom-menu">
          <HelpTab />
          <div className="fake-avatar-container">
            <div className="fake-avatar" />
          </div>
        </div>
      </div>
      <div className="main-wrapper__content">
        <div className="page-content-container">
          <div className="page-content size-standard">
            <div className="kritik-page-header">
              <h2 className="ui page-title">
                <TranslatedText i18nKey="CourseDashboard.Courses" />
              </h2>
            </div>
            <h3 className="header-3 course-list__header">
              <TranslatedText i18nKey="CourseDashboard.Active" />
            </h3>
            <div className="course-list">
              <div className="course-list-item">
                <div className="ui card" style={{ width: 375, height: 200 }}>
                  <div
                    className="content course-card__content"
                    style={{ height: '100%', width: '100%' }}
                  >
                    <div className="header">
                      <a href="">
                        <TranslatedText i18nKey="DemoRegister.DemoCourseTitle" />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  const { user } = state;
  return {
    user,
  };
}
export default withRouter(connect(mapStateToProps, { push })(DemoRegister));
