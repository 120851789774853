/* tslint:disable */
/* eslint-disable */

export type AssignmentFile = {
  name?: string;
  size?: number;
  url?: string;
  _id: string;
};

export type AssignmentEvaluatorNotesFile = {
  name?: string;
  size?: number;
  url?: string;
  _id: string;
};

export type Activity = {
  title: string;
  deleted?: boolean;
  type?: string;
  numEvaluationsToAssign?: number;
  numDisputes?: number;
  numDisputesResolved?: number;
  numFlags?: number;
  numFlagsResolved?: number;
  numSubmissions?: number;
  weight?: number;
  objective?: string;
  instructions?: string;
  files: AssignmentFile[];
  course?: Course['_id'] | Course;
  numericGrading?: boolean;
  user?: User['_id'] | User;
  isIntroActivityTemplate?: boolean;
  isGroupActivity?: boolean;
  isCalibrationActivity?: Activity['_id'] | Activity;
  calibrationSourceRubric?: Rubric['_id'] | Rubric;
  activityType:
    | 'Peer'
    | 'Group'
    | 'Calibration'
    | 'Group Assessment'
    | 'Individual'
    | 'Individual Presentation'
    | 'Group Presentation';
  selfEvaluate?: boolean;
  calibrationCreations: any[];
  fileExtensionsAllowed: string[];
  averageGrade: number[];
  isMultipleTopics?: boolean;
  topics: (Topic['_id'] | Topic)[];
  evaluatorNotes?: string;
  hasEvaluatorNotes?: boolean;
  evaluatorNotesFiles: AssignmentEvaluatorNotesFile[];
  acceptLateSubmissions?: boolean;
  autoAccept?: boolean;
  acceptLateEvaluations?: boolean;
  autoAcceptLateEvaluations?: boolean;
  lmsGradeSyncInformation: {
    lastSyncAt?: Date;
    syncStatus?: 'error' | 'success';
    connectedAssignmentId?: string;
  };
  settings: {
    feedbackCommentsRequired?: boolean;
    minimumWordCountForEvaluations?: number;
  };
  requireFile?: boolean;
  participationSettings: {
    creatorGroups: (Group['_id'] | Group)[];
    creatorStudents: (Student['_id'] | Student)[];
    evaluatorStudents: (Student['_id'] | Student)[];
    evaluatorGroups: (Group['_id'] | Group)[];
    evaluatorType?: 'all' | 'excludeCreators' | 'specificStudents' | 'specificGroups';
  };
  _id: string;
  updatedAt?: Date;
  createdAt?: Date;
  statuses: any[];
  rubric: any;
  removeStudentFromActivityAfterMissedCreation?: boolean;
  startingScore?: number;
  lateCreationPenaltyPercentage: number;
  courseId?: string;
  hasGraceEvaluations?: boolean;
  graceEvaluations?: GraceEvaluation[];
  enableSimilarityReport?: boolean;
};

type GraceEvaluation = {
  studentId: string;
};

export type AssignedGroup = {
  name?: string;
  groups: (Group['_id'] | Group)[];
  course: Course['_id'] | Course;
  assignments: (Activity['_id'] | Activity)[];
  groupsLastEditedAt?: Date;
  deleted?: boolean;
  isInstance?: boolean;
  sourceTemplateId?: string;
  _id: string;
  updatedAt?: Date;
  createdAt?: Date;
};

export type AssignmentStatusStudent = {
  isParticipating?: boolean;
  student?: Student['_id'] | Student;
  _id: string;
};

export type AssignmentStatus = {
  assignment?: Activity['_id'] | Activity;
  name?:
    | 'Draft'
    | 'Create'
    | 'Processing1'
    | 'Evaluate'
    | 'Processing2'
    | 'Feedback'
    | 'Processing3'
    | 'Finalized';
  students: AssignmentStatusStudent[];
  active?: boolean;
  startDate?: Date;
  endDate?: Date;
  hasNotifiedStudents?: boolean;
  hasNotifiedTeachers?: boolean;
  _id: string;
  updatedAt?: Date;
  createdAt?: Date;
};

export type CourseFile = {
  name?: string;
  size?: number;
  url?: string;
  _id: string;
};

export type CourseApprovedStudent = {
  email?: string;
  lastInvitedAt?: Date;
  reInviteCount?: number;
  edlinkPersonId?: string;
  _id: string;
};

export type CourseGradeHistorie = {
  creation: number;
  grading: number;
  writtenEvaluation: number;
  feedback: number;
  assignment: Activity['_id'] | Activity;
  createdAt?: Date;
  numericGrading?: boolean;
  passed?: boolean;
};

export type Course = {
  title: string;
  code?: string;
  section?: string;
  department: string;
  description?: string;
  deleted?: boolean;
  startDate?: Date;
  endDate?: Date;
  completed?: boolean;
  files: CourseFile[];
  tribe: {
    slug?: string;
    groupId?: string;
  };
  approvedStudents: CourseApprovedStudent[];
  approvedCollaborators: string[];
  acceptLateSubmissions?: boolean;
  autoAccept?: boolean;
  acceptLateEvaluations?: boolean;
  autoAcceptLateEvaluations?: boolean;
  user?: User['_id'] | User;
  students: (Student['_id'] | Student)[];
  removedStudents: (Student['_id'] | Student)[];
  collaborators: (User['_id'] | User)[];
  markingScheme: {
    isPercentage?: boolean;
    kritikScore?: number;
    passedSubmissions?: number;
    participationInPeerEvaluation?: number;
    feedbackCommunicationEffectiveness?: number;
    participationInFof?: number;
  };
  price?: number;
  defaultActivityWeight?: number;
  courseSettings: {
    useEvaluationScore?: boolean;
    isDemoCourse?: boolean;
    evalCommentsDisabled?: boolean;
    hideAverageGrade?: boolean;
    revealEvaluatorIdentities?: boolean;
    minimumWordCountForEvaluations?: number;
  };
  lms: {
    connectedCourseId?: string;
    connectedCourseName?: string;
    defaultLineItemIdLink?: string;
    autoRosterSync?: boolean;
    canUseLmsApi?: boolean;
    institutionHasLmsApiSetup?: boolean;
    redirectUrl?: string;
    lmsClientId?: string;
    isRefreshTokenAvailableForLms?: boolean;
  };
  lmsClassId?: string | null;
  institution?: Institution['_id'] | Institution;
  _id: string;
  updatedAt?: Date;
  createdAt?: Date;
  gradeHistories: CourseGradeHistorie[];
  startingScore?: number;
  hasInstitutionEdlinkIntegrations: boolean;
  term?: string;
  institutionSettings: {
    allowDownloadFiles: boolean;
  };
  enableSimilarityReport?: boolean;
};

export type CreationFile = {
  name?: string;
  size?: number;
  url?: string;
  _id: string;
};

export type CreationScoreFile = {
  name?: string;
  size?: number;
  url?: string;
  _id: string;
};

export type CreationScoreTeacherCommentFile = {
  name?: string;
  size?: number;
  url?: string;
  _id: string;
};

export type CreationScoreTeacherComment = {
  submittedAt?: Date;
  text?: string;
  user?: User['_id'] | User;
  viewed: (Student['_id'] | Student)[];
  _id: string;
  updatedAt?: Date;
  createdAt?: Date;
  files: CreationScoreTeacherCommentFile[];
};

export type CreationScore = {
  _id: string;
  user?: User['_id'] | User;
  files: CreationScoreFile[];
  student?: Student['_id'] | Student;
  feedbackOnFeedback: {
    critical?: number;
    motivational?: number;
    comment?: string;
    createdAt?: Date;
    student?: Student['_id'] | Student;
    user?: User['_id'] | User;
  };
  marks?: any;
  createdAt?: Date;
  comment?: string;
  flag?: 'No flag' | 'Flagged' | 'Resolved' | 'Dismissed';
  flaggedOn?: Date;
  flaggedBy?: User['_id'] | User;
  flagResolvedOn?: Date;
  flagResolvedBy?: User['_id'] | User;
  gradingSkillsChange: number[];
  feedbackSkillsChange: {
    critical?: number;
    motivational?: number;
  };
  highlightedCriteria: number[];
  deletedAt?: Date;
  updatedAt?: Date;
  teacherComment?: CreationScoreTeacherComment;
  lateEvaluation?: LateEvaluation;
  submissionStatus?: string;
};

export type CreationTeacherCommentFile = {
  name?: string;
  size?: number;
  url?: string;
  _id: string;
};

export type CreationTeacherComment = {
  submittedAt?: Date;
  text?: string;
  user?: User['_id'] | User;
  viewed: (Student['_id'] | Student)[];
  _id: string;
  updatedAt?: Date;
  createdAt?: Date;
  files: CreationTeacherCommentFile[];
};

export type CreationSubmissionAttempt = {
  requestDate: Date;
  success: boolean;
  resolveDate: Date;
  errorMessage?: string;
  _id: string;
};

export type AiScore = {
  id: string;
  updatedAt: Date;
  createdAt: Date;
  creationId: string;
  score: number;
  rubricCriterionIndex: number;
  explanation: string;
};

export type Creation = {
  content?: string;
  files: CreationFile[];
  deleted?: boolean;
  submissionStatus?:
    | 'EMPTY'
    | 'IN_PROGRESS'
    | 'ON_TIME'
    | 'GRACE'
    | 'LATE'
    | 'MISSED'
    | 'NOT_REQUIRED';
  lateSubmission: {
    status?: 'pending' | 'accepted' | 'rejected';
    reason?: string;
    resolvedDate?: Date;
    isContentLocked?: boolean;
  };
  dispute: {
    status?: 'No dispute' | 'Dispute' | 'Resolved';
    reason?: string;
    disputedOn?: Date;
    resolvedOn?: Date;
    resolvedBy?: User['_id'] | User;
    teacherComment?: string;
  };
  passed?: boolean;
  numEvalsCompleted?: number;
  numEvalsAssigned?: number;
  numFofsCompleted?: number;
  user?: User['_id'] | User;
  student: Student['_id'] | Student;
  assignment: Activity['_id'] | Activity;
  course: Course['_id'] | Course;
  topic?: Topic['_id'] | Topic;
  reviewers: (Student['_id'] | Student)[];
  scores: CreationScore[];
  calibrationCreation?: boolean;
  averageScoreGrid?: any;
  rawWeightedAvgByCriteria: number[];
  teacherScore?: any;
  instructorCreationScoreExplanation?: string;
  teacherFeedbackScore?: number;
  revisedTeacherScore: number[];
  teacherGradingScore?: number;
  teacherWrittenEvaluationScore?: number;
  teacherPassed?: boolean;
  group?: Group['_id'] | Group;
  groupEvalsCompleted?: any;
  groupEvalsAssigned?: any;
  submissionAttempts: CreationSubmissionAttempt[];
  scoreViewStatus?: 'waiting' | 'ready' | 'dismissed' | 'viewed';
  _id: string;
  updatedAt?: Date;
  createdAt?: Date;
  teacherComment?: CreationTeacherComment;
  scoreLastUpdatedByUserName?: string;
  scoreLastUpdatedAt?: Date;
  aiScore?: number;
  aiScores: AiScore[];
  flagByTheCurrentUser?: {
    reason: FlaggedCreationReason;
    explanation: string;
    createdAt: Date;
    resolvedAt?: Date;
    resolvedByUserName?: string;
  };
};

export type EdlinkIntegration = {
  name: string;
  lms: string;
  edlinkIntegrationId: string;
  edlinkIntegrationAccessToken: string;
  institution: Institution['_id'] | Institution;
  deleted?: boolean;
  _id: string;
  updatedAt?: Date;
  createdAt?: Date;
  allowNonLmsCollaborators: boolean;
  lmsClientId: string | null;
  lmsSecret: string | null;
  redirectUrl: string | null;
};

export type EdlinkLmsSync = {
  course: Course['_id'] | Course;
  studentsAdded: string[];
  studentsUpdated: string[];
  studentsRemoved: string[];
  status?: 'success' | 'fail';
  message?: string;
  _id: string;
  updatedAt?: Date;
  createdAt?: Date;
};

export type EdlinkRosterSyncStatus = {
  course: Course['_id'] | Course;
  _id: string;
  createdAt?: Date;
};

export type EvaluationFile = {
  name?: string;
  size?: number;
  url?: string;
  _id: string;
};

export type EvaluationCommentFile = {
  name?: string;
  size?: number;
  url?: string;
  _id: string;
};

export type EvaluationComment = {
  text?: string;
  userId?: User['_id'] | User;
  _id: string;
  updatedAt?: Date;
  createdAt?: Date;
  files: EvaluationCommentFile[];
};

export type EvaluationFlag = {
  status?: 'No flag' | 'Flagged' | 'Dismissed' | 'Resolved';
  flaggedOn?: Date;
  flaggedBy?: User['_id'] | User;
  resolvedOn?: Date;
  resolvedBy?: User['_id'] | User;
  _id: string;
};

export type Evaluation = {
  studentId: Student['_id'] | Student;
  creationId: Creation['_id'] | Creation;
  activityId: Activity['_id'] | Activity;
  marks: number[];
  submissionStatus?: 'EMPTY' | 'ON_TIME' | 'LATE' | 'MISSED';
  files: EvaluationFile[];
  highlightedCriteria: number[];
  content?: string;
  deletedAt?: Date;
  gradingSkillsChange: number[];
  feedbackSkillsChange: {
    critical?: number;
    motivational?: number;
  };
  feedbackStudentId?: Student['_id'] | Student;
  _id: string;
  updatedAt?: Date;
  createdAt?: Date;
  comment?: EvaluationComment;
  flag?: EvaluationFlag;
  lateEvaluation?: LateEvaluation;
};

export type Feedback = {
  evaluationId: Evaluation['_id'] | Evaluation;
  studentId: Student['_id'] | Student;
  creationId: Creation['_id'] | Creation;
  activityId: Activity['_id'] | Activity;
  content?: string;
  motivationalScore?: number;
  criticalScore?: number;
  submissionStatus?: 'EMPTY' | 'ON_TIME' | 'LATE' | 'MISSED';
  deletedAt?: Date;
  _id: string;
  updatedAt?: Date;
  createdAt?: Date;
};

/**
 * Lean version of GroupDocument
 *
 * This has all Mongoose getters & functions removed. This type will be returned from `GroupDocument.toObject()`. To avoid conflicts with model names, use the type alias `GroupObject`.
 * ```
 * const groupObject = group.toObject();
 * ```
 */
export type Group = {
  name: string;
  members: (Student['_id'] | Student)[];
  leader?: Student['_id'] | Student;
  course: Course['_id'] | Course;
  assignments: (Activity['_id'] | Activity)[];
  deleted?: boolean;
  _id: string;
  updatedAt?: Date;
  createdAt?: Date;
  lmsGroupId?: string;
};

/**
 * Lean version of InstitutionDocument
 *
 * This has all Mongoose getters & functions removed. This type will be returned from `InstitutionDocument.toObject()`. To avoid conflicts with model names, use the type alias `InstitutionObject`.
 * ```
 * const institutionObject = institution.toObject();
 * ```
 */
export type Institution = {
  name: string;
  salesTax: number;
  identifier: string;
  currency: 'usd' | 'eur' | 'cad' | 'sgd' | 'gbp' | 'aud';
  defaultCoursePrice?: number;
  domain: string;
  _id: string;
  updatedAt?: Date;
  createdAt?: Date;
  isSandbox: boolean;
};

export type OauthAccessToken = {
  accessToken: string;
  clientId?: string;
  user: User['_id'] | User;
  expires: Date;
  deleted?: boolean;
  _id: string;
  updatedAt?: Date;
  createdAt?: Date;
};

export type OauthRefreshToken = {
  refreshToken: string;
  clientId?: string;
  user: User['_id'] | User;
  expires?: Date;
  deleted?: boolean;
  _id: string;
  updatedAt?: Date;
  createdAt?: Date;
};

export type ResetToken = {
  expiresAt: Date;
  valid?: boolean;
  userEmail: string;
  _id: string;
  updatedAt?: Date;
  createdAt?: Date;
};

export type RubricCriteria = {
  name: string;
  useMode?: boolean;
  weight: number;
};

export type Rubric = {
  user?: User['_id'] | User;
  default?: boolean;
  deleted?: boolean;
  isTemplate?: boolean;
  isBaseTemplate?: boolean;
  course?: Course['_id'] | Course;
  assignment?: Activity['_id'] | Activity;
  name?: string;
  criteria: RubricCriteria[];
  levels: string[];
  grid: string[][];
  passCriteriaRule?: 'any' | 'majority' | 'all';
  passLevel?: string;
  description?: string;
  _id: string;
  updatedAt?: Date;
  createdAt?: Date;
  passFailCondition?: 'Critieria' | 'MinimumPercentage';
  minimumPercentage?: number;
};

export type Scheduling = {
  customTime: number[];
  course: Course['_id'] | Course;
  creationInterval?: number;
  gracePeriodInterval?: number;
  evaluationInterval?: number;
  feedbackInterval?: number;
  _id: string;
  updatedAt?: Date;
  createdAt?: Date;
};

export type SpotlightTag = {
  name: 'Above & Beyond' | 'Innovative' | 'Critical & Motivational' | 'Authentic' | 'Spotlight';
  definition: string;
  type: 'Creation' | 'Evaluation';
  deleted?: boolean;
  _id: string;
  updatedAt?: Date;
  createdAt?: Date;
};

export type Spotlight = {
  activity: Activity['_id'] | Activity;
  course: Course['_id'] | Course;
  type: 'Creation' | 'Evaluation';
  reference: string;
  tag: SpotlightTag['_id'] | SpotlightTag;
  comment?: string;
  studentNotified?: boolean;
  deleted?: boolean;
  creationId?: Creation['_id'] | Creation;
  evaluationId?: Evaluation['_id'] | Evaluation;
  _id: string;
  updatedAt?: Date;
  createdAt?: Date;
  status: 'published' | 'draft';
};

export type StudentScoreHistorie = {
  assignment: Activity['_id'] | Activity;
  criticalScore: number;
  motivationalScore: number;
  gradingScore: number;
  _id: string;
};

export type StudentGradeHistorie = {
  creation: number;
  grading: number;
  writtenEvaluation: number;
  feedback: number;
  assignment: Activity['_id'] | Activity;
  createdAt?: Date;
  numericGrading?: boolean;
  passed?: boolean;
};

export type Student = {
  user: User['_id'] | User;
  course: Course['_id'] | Course;
  deleted?: boolean;
  notificationSettings: {
    receiveEmails?: boolean;
    receiveSMS?: boolean;
    receiveSubmissionConfirmEmails?: boolean;
  };
  score?: number;
  scoreHistories: StudentScoreHistorie[];
  paid?: boolean;
  removedOn?: Date;
  isDemoCourseExampleStudent?: boolean;
  edlinkPersonId?: string;
  _id: string;
  updatedAt?: Date;
  createdAt?: Date;
  gradeHistories: StudentGradeHistorie[];
};

export type Token = {
  user?: User['_id'] | User;
  token?: string;
  _id: string;
  updatedAt?: Date;
  createdAt?: Date;
};

export type Topic = {
  name?: string;
  students: (Student['_id'] | Student)[];
  activity?: Activity['_id'] | Activity;
  _id: string;
  updatedAt?: Date;
  createdAt?: Date;
};

export type UserGlobalPermissions = 'isAdmin' | 'canCreateCourse';

export type User = {
  email?: string;
  phoneNumber?: string;
  accountStatus?: 'active' | 'inactive';
  password?: string;
  deleted?: boolean;
  role?: 'student' | 'admin' | 'teacher';
  institution?: Institution['_id'] | Institution;
  profile: {
    name?: string;
    firstName?: string;
    lastName?: string;
    picture?: string;
  };
  tutorials: {
    activityTutorial?: boolean;
    scoreTutorial?: boolean;
  };
  isDemo?: boolean;
  edlinkPersonId?: string;
  _id: string;
  updatedAt?: Date;
  createdAt?: Date;
  permissions?: UserGlobalPermissions[];
};

export type LateEvaluation = {
  id: string;
  evaluationId: string;
  status: LateEvaluationStatus;
  reason: string;
  resolvedDate: Date;
  updatedAt: Date;
  createdAt: Date;
};

export enum LateEvaluationStatus {
  Pending = 'Pending',
  Accepted = 'Accepted',
  Rejected = 'Rejected',
}
