import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getCourse } from 'selectors/course';
import { getAssignmentsFromGroupSet } from 'selectors/activity';
import { getGroupList, getGroupSetList } from 'selectors/group';
import { getGroups } from 'actions/courses';
import { getAllGroupSets, isGroupSetInUse, deleteGroupSet } from 'actions/groups';
import { getAssignments } from 'actions/activity';
import { openGlobalModal } from 'actions/modals';
import NoticeBoard from 'components/layout/NoticeBoard';
import GenerateGroupSetModal from 'components/GroupManager/GenerateGroupSetModal';
import Button from 'components/buttons/Button';
import ButtonWithSelect from 'components/buttons/ButtonWithSelect';
import { Select, MenuItem, Popover, ClickAwayListener } from '@material-ui/core';
import LinkIcon from '@material-ui/icons/Link';
import GroupMember from 'components/GroupManager/GroupMember';
import EmptyPlaceholder from 'components/layout/EmptyPlaceholder';
import { selectGroupSet } from 'actions/select';
import GROUP_IMAGE from 'images/group-icon.svg';
import Confirm from 'components/modals/ConfirmModal';
import { selectIsShowUploadView, showUploadView } from 'redux/groupManager';
import { searchUserList } from '@kritik/utils/filter';
import * as GroupUtils from '@kritik/utils/group';
import GroupSearch from './GroupSearch';
import AddGroupBtn from './AddGroupBtn';
import { enqueueSnackbar } from 'notistack';
import { withRouter } from 'utils/withRouter';
import { localize } from 'locales/index';
import { Group } from '@kritik/types.generated';
import { getBase64StringFromObject } from 'utils/general';
import { AuthUser } from 'app-types';
import { Groups } from './Groups';
import { TranslatedText } from 'components/TranslatedText';
import { syncGroupsMutation } from 'hooks/group';
import { ButtonContainer } from 'components/buttons';
import moment from 'moment';
import { useFetchCourseLastGroupSyncsResults } from 'hooks/lms';
import { InlineInformation } from 'components/layout';
import { isCourseConnected } from 'utils/lms';

const GENERATE_GROUP_SET = 'Generate Group Set';
const UPLOAD_GROUP_SET = 'Upload Group Set';
const groupSetSelectOptions = [
  {
    label: GENERATE_GROUP_SET,
    value: GENERATE_GROUP_SET,
    testid: 'generate-group-set',
  },
  {
    label: UPLOAD_GROUP_SET,
    value: UPLOAD_GROUP_SET,
    testid: 'upload-group-set',
  },
];

const mapStateToProps = (state: any) => {
  return {
    groups: getGroupList(state, { withMembers: true }),
    courseId: state.selected.courseId,
    groupSetId: state.selected.groupSetId,
    groupSetList: getGroupSetList(state),
    course: getCourse(state),
    user: state.user,
    relatedAssignments: getAssignmentsFromGroupSet(state, state.selected.groupSetId),
    isShowUploadView: selectIsShowUploadView(state),
  };
};

type GroupManagerState = any;

type GroupManagerProps = {
  course: ReturnType<typeof getCourse>;
  courseId: string;
  user: {
    authUser: AuthUser;
  };
  relatedAssignments: any;
  getGroups: ({ courseId }: { courseId: string }) => void;
  getAllGroupSets: ({ courseId }: { courseId: string }) => void;
  getAssignments: ({ courseId }: { courseId: string }) => void;
  groupSetList: any[];
  groups: Group[];
  openGlobalModal: (any) => void;
  selectGroupSet: (string) => void;
  isGroupSetInUse: ({ groupSetId }: { groupSetId: string }) => Promise<any>;
  deleteGroupSet: ({ groupSetId }: { groupSetId: string }) => Promise<any>;
  showUploadView: () => void;
  groupSetId: string;
};

class GroupManager extends React.Component<GroupManagerProps, GroupManagerState> {
  gridRef: any;
  constructor(props: GroupManagerProps) {
    super(props);
    this.state = {
      selectedGroupSet: 0,
      selectedGroups: [],
      isGroupSetInUse: false,
      isDeletingGroupSet: false,
      loaded: false,
      searchParam: '',
      authorizeGroupSyncResult: false,
    };
    this.gridRef = React.createRef();
  }

  async componentDidMount() {
    await Promise.all([
      this.props.getGroups({ courseId: this.props.courseId }),
      this.props.getAllGroupSets({ courseId: this.props.courseId }),
      this.props.getAssignments({ courseId: this.props.courseId }),
    ]);

    this.setSelectedGroupSet();
    this.setState({ loaded: true });

    const authorizeGroupSyncResult = new URLSearchParams(window.location.search).get(
      'authorizeGroupSyncResult'
    );
    if (authorizeGroupSyncResult === 'success') {
      this.setState({ authorizeGroupSyncResult: true });
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete('authorizeGroupSyncResult');
      window.history.replaceState({}, '', `${window.location.pathname}?${searchParams}`);
    }
  }

  componentDidUpdate(prevProps: GroupManagerProps, prevState: GroupManagerState) {
    if (prevProps.groupSetList && prevProps.groupSetList.length < this.props.groupSetList.length) {
      this.setState({ selectedGroupSet: 0 });
    }
    if (prevState.selectedGroupSet != this.state.selectedGroupSet) {
      this.setSelectedGroupSet();
    }
    if (prevState.selectedGroups != this.state.selectedGroups && this.gridRef.current) {
      this.gridRef.current.resetAfterIndices({
        columnIndex: 0,
        rowIndex: 0,
        shouldForceUpdate: true,
      });
    }
    if (prevProps.groups != this.props.groups) {
      this.setSelectedGroups();
    }
  }

  generateGroupSet() {
    const props = {
      id: 'generate-group-set-modal',
    };
    this.props.openGlobalModal({ props });
  }

  onValueChange = (ev: any) => {
    this.setState({ selectedGroupSet: ev.target.value });
  };

  setSelectedGroupSet() {
    const groupSet = this.props.groupSetList[this.state.selectedGroupSet];
    if (groupSet) {
      this.props.selectGroupSet(groupSet._id);
    }
  }

  setSelectedGroups() {
    const groupSet = this.props.groupSetList[this.state.selectedGroupSet];
    let groups = [];
    if (groupSet) {
      const groupIds = new Set(groupSet.groups);
      groups = this.props.groups.filter((group: any) => {
        return groupIds.has(group._id);
      });

      this.props.isGroupSetInUse({ groupSetId: groupSet._id }).then((result: any) => {
        this.setState({ isGroupSetInUse: result });
      });
    }
    this.setState({ selectedGroups: groups });
  }

  toggleDeleteGroupSet() {
    this.setState({ isDeletingGroupSet: !this.state.isDeletingGroupSet });
  }

  handleDeleteGroupSet() {
    const groupSet = this.props.groupSetList[this.state.selectedGroupSet];
    this.props.deleteGroupSet({ groupSetId: groupSet._id }).then(() => {
      this.setState({ selectedGroupSet: 0 });
      this.setSelectedGroupSet();
      this.toggleDeleteGroupSet();
    });
  }

  courseHasGroups() {
    return this.props.groupSetList && this.props.groupSetList.length > 0;
  }

  handleUploadGroupSet() {
    this.props.showUploadView();
  }

  handleSelectionChange = (event: any) => {
    switch (event.target.value) {
      case GENERATE_GROUP_SET:
        return this.generateGroupSet();
      case UPLOAD_GROUP_SET:
        return this.handleUploadGroupSet();
      default:
        return null;
    }
  };

  renderGroupSetSelector() {
    const canUseLmsApi = this.props.course?.lms?.canUseLmsApi;
    return (
      <div className="group-manager__select-set">
        <div className="group-manager__groupSet-header">
          <div className="group-manager__select-set-menu">
            <div className="group-manager__select-set-title" id="select-set-label">
              <TranslatedText i18nKey="RosterManager.GroupTab.GroupSet" />
            </div>
            <Select
              className="group-manager__group-dropdown"
              value={this.state.selectedGroupSet}
              onChange={this.onValueChange}
              disableUnderline
              disabled={!this.courseHasGroups()}
            >
              {!this.courseHasGroups() && (
                <MenuItem key="show-all-groups" value={0}>
                  <TranslatedText i18nKey="RosterManager.GroupTab.NoGroupSetSelected" />
                </MenuItem>
              )}
              {this.props.groupSetList
                .sort((a: any, b: any) => {
                  if (a.lmsGroupSetId !== null && b.lmsGroupSetId === null) return -1;
                  if (a.lmsGroupSetId === null && b.lmsGroupSetId !== null) return 1;
                  return 0;
                })
                .map((groupSet: any, i: any) => {
                  return (
                    <MenuItem key={groupSet._id} value={i}>
                      {groupSet.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </div>
          {!canUseLmsApi && (
            <ButtonWithSelect
              onChange={this.handleSelectionChange}
              options={groupSetSelectOptions}
              type="secondary"
              title={<TranslatedText i18nKey="RosterManager.GroupTab.CreateGroupSet" />}
              testid="create-group-set"
            />
          )}
        </div>
      </div>
    );
  }

  onSearch = (searchParam: any) => {
    this.setState({
      searchParam,
    });
  };

  handleAuthorizeLms = () => {
    // @ts-expect-error TS(2304) FIXME: Cannot find name 'App'.
    const baseUrl = App.config.get('baseUrl');
    const { course } = this.props;
    const redirectState = {
      userId: this.props.user.authUser._id,
      courseId: course._id,
      redirectSource: 'group-manager',
    };

    window.location.href = `${course.lms.redirectUrl}/login/oauth2/auth?client_id=${
      course.lms.lmsClientId
    }&state=${getBase64StringFromObject(
      redirectState
    )}&redirect_uri=${baseUrl}/api/v1/lms/oauth&response_type=code`;
  };

  render() {
    const { course, relatedAssignments } = this.props;

    if (!this.state.loaded) {
      return null;
    }
    const authorizeGroupSyncResult = new URLSearchParams(window.location.search).get(
      'authorizeGroupSyncResult'
    );

    if (authorizeGroupSyncResult === 'fail') {
      enqueueSnackbar(localize({ message: 'RosterManager.SyncGroups.Notice.Error' }), {
        title: localize({ message: 'RosterManager.SyncGroups.Notice.Title' }),
        variant: 'error',
        testid: 'group-sync-enabled-snackbar',
      });
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.delete('authorizeGroupSyncResult');
      window.history.replaceState({}, '', `${window.location.pathname}?${searchParams}`);
    }

    const courseHasStudents =
      course.students &&
      course.students.filter((student: any) => {
        return student;
      }).length > 0;

    const canUseLmsApi = course?.lms?.canUseLmsApi;
    const institutionHasLmsApiSetup = course?.lms?.institutionHasLmsApiSetup;
    const isRefreshTokenAvailableForLms = course?.lms?.isRefreshTokenAvailableForLms;
    const isCourseOwner = course.user._id === this.props.user.authUser._id;
    const groupSet = this.props.groupSetList?.[this.state.selectedGroupSet];

    if (!canUseLmsApi) {
      /* This is handling the case where
        - Institution is setup for group sync
        - Course was manually connected
        - lmsClassId is missing
        - As a result, canUseLmsApi is false because it's missing the lmsClassId
      */
      if (institutionHasLmsApiSetup && isCourseConnected(course)) {
        return (
          <div className="group-manager">
            <div className="group-manager__empty">
              <EmptyPlaceholder
                image={GROUP_IMAGE}
                title={<TranslatedText i18nKey="RosterManager.GroupTab.NoStudent.Title" />}
              >
                <NoticeBoard>
                  <TranslatedText i18nKey="RosterManager.GroupTab.SyncGroups.MissingLmsClassId" />
                </NoticeBoard>
              </EmptyPlaceholder>
            </div>
          </div>
        );
      }
      if (!courseHasStudents) {
        return (
          <div className="group-manager">
            <div className="group-manager__empty">
              <EmptyPlaceholder
                image={GROUP_IMAGE}
                title={<TranslatedText i18nKey="RosterManager.GroupTab.NoStudent.Title" />}
              >
                <p>
                  <TranslatedText i18nKey="RosterManager.GroupTab.NoStudent.Content" />
                </p>
                <NoticeBoard type="caution">
                  <TranslatedText i18nKey="RosterManager.GroupTab.NoStudent.Warning" />
                </NoticeBoard>
              </EmptyPlaceholder>
            </div>
          </div>
        );
      }

      if (!this.courseHasGroups()) {
        return (
          <div className="group-manager">
            <GroupManagerWarnings course={course} groupSet={groupSet} />
            <div className="group-manager__empty">
              <EmptyPlaceholder
                image={GROUP_IMAGE}
                title={<TranslatedText i18nKey="RosterManager.GroupTab.NoGroup.Title" />}
              >
                <p>
                  <TranslatedText i18nKey="RosterManager.GroupTab.NoGroup.Content" />
                </p>
                {this.props.course.students.length >= 4 && (
                  <ButtonWithSelect
                    title={<TranslatedText i18nKey="RosterManager.GroupTab.NoGroup.Button" />}
                    options={groupSetSelectOptions}
                    onChange={this.handleSelectionChange}
                    testid="create-group-set"
                  />
                )}
              </EmptyPlaceholder>
            </div>
            <GenerateGroupSetModal />
          </div>
        );
      }
    }

    if (canUseLmsApi) {
      if (!isRefreshTokenAvailableForLms && !this.courseHasGroups()) {
        return (
          <div className="group-manager">
            <div className="group-manager__empty">
              <EmptyPlaceholder
                image={GROUP_IMAGE}
                title={<TranslatedText i18nKey="RosterManager.SyncGroups.NoToken.Title" />}
              >
                <p>
                  <TranslatedText i18nKey="RosterManager.SyncGroups.NoToken.Content" />
                </p>
                <Button
                  type="primary"
                  onClick={this.handleAuthorizeLms}
                  disabled={!isCourseOwner}
                  testid="enable-group-sync"
                >
                  <TranslatedText i18nKey="RosterManager.SyncGroups.EnableSync.Btn.Title" />
                  <i
                    className="fa fa-external-link"
                    aria-hidden="true"
                    style={{ marginLeft: 8 }}
                  ></i>
                </Button>
                <NoticeBoard
                  type="caution"
                  title={
                    isCourseOwner ? (
                      <TranslatedText i18nKey="RosterManager.SyncGroups.NoToken.CourseOwner.Notice.Title" />
                    ) : (
                      <TranslatedText i18nKey="RosterManager.SyncGroups.NoToken.NotCourseOwner.Notice.Title" />
                    )
                  }
                >
                  {isCourseOwner ? (
                    <TranslatedText i18nKey="RosterManager.SyncGroups.NoToken.CourseOwner.Notice.Content" />
                  ) : (
                    <TranslatedText i18nKey="RosterManager.SyncGroups.NoToken.NotCourseOwner.Notice.Content" />
                  )}
                </NoticeBoard>
              </EmptyPlaceholder>
            </div>
          </div>
        );
      }
      if (!this.courseHasGroups()) {
        return (
          <div className="group-manager">
            {this.state.authorizeGroupSyncResult && (
              <NoticeBoard type="success">
                <TranslatedText i18nKey="RosterManager.SyncGroups.Notice.Success" />
              </NoticeBoard>
            )}
            <div className="group-manager__header">
              {this.renderGroupSetSelector()}
              {canUseLmsApi && isRefreshTokenAvailableForLms && (
                <SyncGroupsButton course={course} />
              )}
            </div>
            <div className="group-manager__container-empty">
              <TranslatedText i18nKey="RosterManager.GroupTab.NoGroupSetYet" />
            </div>
          </div>
        );
      }
    }

    return (
      <div className="group-manager">
        {this.state.authorizeGroupSyncResult && (
          <NoticeBoard type="success">
            <TranslatedText i18nKey="RosterManager.SyncGroups.Notice.Success" />
          </NoticeBoard>
        )}
        <div className="group-manager__header">
          {this.renderGroupSetSelector()}
          {canUseLmsApi && isRefreshTokenAvailableForLms && <SyncGroupsButton course={course} />}
        </div>
        {canUseLmsApi && !isRefreshTokenAvailableForLms && (
          <NoticeBoard title="Group sync has been enabled" type="caution">
            <div className="group-manager__finish-setup">
              <div>
                <TranslatedText i18nKey="RosterManager.SyncGroups.EnableSync.FinishSetup" />
              </div>
              <Button type="secondary" onClick={this.handleAuthorizeLms} disabled={!isCourseOwner}>
                <TranslatedText i18nKey="RosterManager.SyncGroups.EnableSync.Btn.Title" />
                <i className="fa fa-external-link" aria-hidden="true" style={{ marginLeft: 8 }}></i>
              </Button>
            </div>
          </NoticeBoard>
        )}
        <div className="group-manager__container">
          <div style={{ padding: 10 }}>
            <div className="group-manager__title">
              {Boolean(groupSet) && <h3 data-testid="group-set-name">{groupSet.name}</h3>}
              {relatedAssignments?.length > 0 && (
                <LockedSetWarning relatedAssignments={relatedAssignments} />
              )}
              {Boolean(groupSet?.lmsGroupSetId) && (
                <div
                  className="group-manager__connected-group-set"
                  data-testid="connected-group-set"
                >
                  <LinkIcon />
                  <TranslatedText i18nKey="RosterManager.GroupTab.ConnectedGroupSet" />
                </div>
              )}
            </div>
            <GroupManagerWarnings course={course} groupSet={groupSet} />
            <div className="group-manager__actions">
              <GroupSearch onSearch={this.onSearch} />
              <div className="group-manager__actions-right">
                {!canUseLmsApi && <AddGroupBtn disabled={!!this.state.searchParam} />}
                {!canUseLmsApi && !this.state.isGroupSetInUse && (
                  <Button
                    onClick={() => {
                      return this.toggleDeleteGroupSet();
                    }}
                    type="secondary"
                  >
                    <TranslatedText i18nKey="RosterManager.GroupTab.DeleteGroupSet" />
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="group-manager__body">
            {!canUseLmsApi && this.courseHasGroups() && (
              <UnassignedStudents
                selectedGroups={this.state.selectedGroups}
                course={course}
                searchParam={this.state.searchParam}
              />
            )}
            <Groups
              searchParam={this.state.searchParam}
              selectedGroups={this.state.selectedGroups}
              isGroupSetInUse={this.state.isGroupSetInUse}
              gridRef={this.gridRef}
              readOnly={canUseLmsApi}
            />
          </div>

          <GenerateGroupSetModal />
          <Confirm
            isOpen={this.state.isDeletingGroupSet}
            title={<TranslatedText i18nKey="RosterManager.GroupTab.DeleteGroupSetModal.Title" />}
            description={
              <TranslatedText i18nKey="RosterManager.GroupTab.DeleteGroupSetModal.Description" />
            }
            onCancel={() => {
              return this.toggleDeleteGroupSet();
            }}
            cancelButton="Cancel"
            onConfirm={() => {
              if (this.state.isDeletingGroupSet) {
                return this.handleDeleteGroupSet();
              }
            }}
            confirmButton={<TranslatedText i18nKey="RosterManager.GroupTab.DeleteGroupSet" />}
          />
        </div>
      </div>
    );
  }
}

function GroupManagerWarnings({ course, groupSet }) {
  const canUseLmsApi = course?.lms?.canUseLmsApi;
  const showPendingStudentWarning =
    course.approvedStudents.length >= 1 && course.students.length > 4;
  const showMissingStudentWarning = course.students.length < 4 && !canUseLmsApi;
  const isReadOnlyGroupSet = canUseLmsApi && !groupSet?.lmsGroupSetId;

  return (
    <>
      {showPendingStudentWarning && (
        <NoticeBoard type="information">
          <TranslatedText
            i18nKey={
              canUseLmsApi
                ? 'RosterManager.GroupTab.Warnings.PendingStudent.GroupSync.Content'
                : 'RosterManager.GroupTab.Warnings.PendingStudent.Content'
            }
            values={{ pendingStudentsCount: course.approvedStudents.length }}
          />
        </NoticeBoard>
      )}
      {isReadOnlyGroupSet && (
        <NoticeBoard type="information">
          <TranslatedText i18nKey={'RosterManager.GroupTab.Warnings.ReadOnly'} />
        </NoticeBoard>
      )}
      {showMissingStudentWarning && (
        <NoticeBoard
          type="information"
          title={<TranslatedText i18nKey="RosterManager.GroupTab.Warnings.MissingStudent.Title" />}
          testid="cannot-create-groups-notice"
        >
          <TranslatedText i18nKey="RosterManager.GroupTab.Warnings.MissingStudent.Content" />
        </NoticeBoard>
      )}
    </>
  );
}

function LockedSetWarning({ relatedAssignments }) {
  const [showPopup, setShowPopup] = useState(false);
  const [anchor, setAnchor] = useState(null);

  function openShowSyncGroupsPopup(event: any) {
    setAnchor(event.currentTarget);
    setShowPopup(true);
  }
  return (
    <>
      <div className="group-manager__related-activities" onMouseEnter={openShowSyncGroupsPopup}>
        <i className="fa fa-info-circle" />
        <TranslatedText
          i18nKey={
            relatedAssignments.length === 1
              ? 'RosterManager.GroupTab.Warnings.LockedSet.Title'
              : 'RosterManager.GroupTab.Warnings.LockedSet.Plural.Title'
          }
          values={{ activities: relatedAssignments.length }}
        />
      </div>
      <Popover
        open={showPopup}
        anchorEl={anchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'left' }}
        onClose={() => setShowPopup(false)}
      >
        <ClickAwayListener onClickAway={() => setShowPopup(false)}>
          <div className="sync-groups-popup">
            <div className="sync-groups-popup__title">
              <TranslatedText i18nKey="RosterManager.GroupTab.LockedSet.Popup.Title" />
            </div>
            <ul>
              {relatedAssignments.map((activity) => {
                return <li key={activity.id}>{activity.title}</li>;
              })}
            </ul>
            <div>
              <TranslatedText i18nKey="RosterManager.GroupTab.LockedSet.Popup.Info" />
            </div>
          </div>
        </ClickAwayListener>
      </Popover>
    </>
  );
}

function UnassignedStudents({ selectedGroups, course, searchParam }) {
  const filterUnassignedStudents = (students: any) => {
    const fromStudent = true;
    return searchUserList(students, searchParam, fromStudent);
  };

  const getUnassignedStudents = () => {
    const assignedStudentIds = selectedGroups.reduce((acc: any, group: any) => {
      const groupMemberIds = GroupUtils.getGroupMemberIds(group);
      acc.push(...groupMemberIds);
      return acc;
    }, []);
    const assignedStudentIdsSet = new Set(assignedStudentIds);
    const unassignedStudentList = course.students.filter((student: any) => {
      return student && !student.removedOn && !assignedStudentIdsSet.has(student._id);
    });

    return filterUnassignedStudents(unassignedStudentList);
  };

  const unassignedStudentList = getUnassignedStudents().sort((a, b) =>
    a.user.profile.name.localeCompare(b.user.profile.name, undefined, {
      sensitivity: 'base',
      ignorePunctuation: true,
    })
  );

  if (unassignedStudentList.length === 0) {
    return null;
  }

  return (
    <div className="group-manager__unassigned-students">
      <h3 className="header-3">
        <TranslatedText i18nKey="RosterManager.GroupTab.UnassignedStudents.Title" />
      </h3>
      <p>
        <TranslatedText i18nKey="RosterManager.GroupTab.UnassignedStudents.Content" />
      </p>
      <div
        className="group-manager__unassigned-students-container"
        data-testid="unassigned-students"
      >
        {unassignedStudentList.map((student: any, idx: any) => {
          return (
            <GroupMember
              key={`group_card_member_${student.user._id}`}
              student={student}
              groups={selectedGroups}
              testid={`unassigned-student-${idx}`}
            />
          );
        })}
      </div>
    </div>
  );
}

function SyncGroupsButton({ course }) {
  const [showSyncGroupsPopup, setShowSyncGroupsPopup] = useState(false);
  const [anchor, setAnchor] = useState(null);

  function openShowSyncGroupsPopup(event: any) {
    setAnchor(event.currentTarget);
    setShowSyncGroupsPopup(true);
  }

  const closePopup = () => setShowSyncGroupsPopup(false);
  const syncGroupMutation = syncGroupsMutation({
    onSuccess: () => {
      closePopup();
      enqueueSnackbar(
        localize({ message: 'RosterManager.GroupTab.SyncGroups.SnackBar.Description' }),
        {
          title:
            localize({ message: 'RosterManager.GroupTab.SyncGroups.SnackBar.Title' }) +
            moment().format('MMM DD @ hh:mm'),
          variant: 'success',
          testid: 'group-sync-success-snackbar',
        }
      );
    },
  });

  const { data } = useFetchCourseLastGroupSyncsResults({
    courseId: course._id,
  });

  return (
    <div>
      <Button type="primary" onClick={openShowSyncGroupsPopup} chevron="down" testid="sync-groups">
        <TranslatedText i18nKey="RosterManager.GroupTab.SyncGroups" />
      </Button>
      <Popover
        open={showSyncGroupsPopup}
        anchorEl={anchor}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        onClose={closePopup}
      >
        <ClickAwayListener onClickAway={closePopup}>
          <div className="sync-groups-popup">
            <div className="sync-groups-popup__title">
              <TranslatedText i18nKey="RosterManager.GroupTab.SyncGroups.Popup.Title" />
            </div>
            {Boolean(data?.lastSuccessfulSync) && (
              <div className="sync-groups-popup__info">
                <TranslatedText i18nKey="RosterManager.GroupTab.SyncGroups.Popup.LastSyncedOn" />
                {moment(data.lastSuccessfulSync.createdAt).format('MMM DD @ hh:mm')}
              </div>
            )}
            {data?.lastSync?.status === 'fail' && (
              <InlineInformation type="danger" style={{ marginBottom: 8 }}>
                <TranslatedText i18nKey="RosterManager.GroupTab.SyncGroups.Error" />
              </InlineInformation>
            )}
            <div className="sync-groups-popup__description">
              <TranslatedText i18nKey="RosterManager.GroupTab.SyncGroups.Popup.Description1" />
              <ul>
                <li>
                  <TranslatedText i18nKey="RosterManager.GroupTab.SyncGroups.Popup.Description2" />
                </li>
                <li>
                  <TranslatedText i18nKey="RosterManager.GroupTab.SyncGroups.Popup.Description3" />
                </li>
                <li>
                  <TranslatedText i18nKey="RosterManager.GroupTab.SyncGroups.Popup.Description4" />
                </li>
              </ul>
            </div>
            <ButtonContainer>
              <Button
                type="primary"
                onClick={() => {
                  syncGroupMutation.mutate({ courseId: course._id });
                }}
                testid="start-sync"
              >
                <TranslatedText i18nKey="RosterManager.GroupTab.StartSyncGroups" />
              </Button>
              <Button type="secondary">
                <TranslatedText i18nKey="LearnMore" />
                <i className="fa fa-external-link" aria-hidden="true" style={{ marginLeft: 8 }}></i>
              </Button>
            </ButtonContainer>
          </div>
        </ClickAwayListener>
      </Popover>
    </div>
  );
}

export default withRouter(
  connect(mapStateToProps, {
    getGroups,
    getAllGroupSets,
    openGlobalModal,
    isGroupSetInUse,
    deleteGroupSet,
    selectGroupSet,
    getAssignments,
    showUploadView,
  })(GroupManager)
);
