import { localize } from 'locales/index';

export const ROW_HEIGHT = 100;

export const LIST_BY_TYPES = {
  STUDENT: 'Student',
  GROUP: 'Group',
};

export const TABLE_TYPE_OPTIONS = {
  PROGRESS: 'Progress',
  SCORING: 'Scoring',
  DISPUTE: 'Dispute',
  SCORE_COMPARISON: 'Score Comparison',
} as const;

export const FILTERS = {
  SHOW_ALL: 'SHOW_ALL',
  MISSED_CREATIONS: 'MISSED_CREATIONS',
  LATE_CREATIONS: 'LATE_CREATIONS',
  OVERDUE_CREATIONS: 'OVERDUE_CREATIONS',
  NOT_GRADED: 'NOT_GRADED',
  DISPUTES: 'DISPUTES',
  FLAGS: 'FLAGS',
};

export const TABLE_HEADERS = {
  NAME: localize({ message: 'CreationList.TableHeaders.Name' }),
  CREATE: localize({ message: 'CreationList.TableHeaders.Create' }),
  EVALUATE: localize({ message: 'CreationList.TableHeaders.Evaluate' }),
  FEEDBACK: localize({ message: 'CreationList.TableHeaders.Feedback' }),
  ISSUES: localize({ message: 'CreationList.TableHeaders.Issues' }),
  OVERALL: localize({ message: 'CreationList.TableHeaders.Overall' }),
  EVALUATIONS_RECEIVED: localize({ message: 'CreationList.TableHeaders.EvaluationsReceived' }),
  EVALUATIONS_GIVEN: localize({ message: 'CreationList.TableHeaders.EvaluationsGiven' }),
  FEEDBACK_RECEIVED: localize({ message: 'CreationList.TableHeaders.FeedbackReceived' }),
  RESOLVED_DATE: localize({ message: 'CreationList.TableHeaders.ResolvedDate' }),
  DISPUTED_DATE: localize({ message: 'CreationList.TableHeaders.DisputedDate' }),
  STATUS: localize({ message: 'CreationList.TableHeaders.Status' }),
};

export const EVALUATE_HEADER_LABEL = {
  GIVEN: localize({ message: 'CreationList.EvaluateHeaderLabel.Given' }),
  RECEIVED: localize({ message: 'CreationList.EvaluateHeaderLabel.Received' }),
};

export const SORT_OPTIONS = TABLE_HEADERS;

export default {
  ROW_HEIGHT,
  TABLE_TYPE_OPTIONS,
  SORT_OPTIONS,
  EVALUATE_HEADER_LABEL,
};
