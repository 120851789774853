import React from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { TranslatedText } from 'components/TranslatedText';
import { localize } from 'locales';

import { setTableType } from 'redux/creationTable/actions';
import { selectCreationTableViewType } from 'redux/creationTable/select';
import ButtonWithSelect from 'components/buttons/ButtonWithSelect';
import { MenuLabel } from 'components/CreationList/Menu/Input';
import { TABLE_TYPE_OPTIONS } from 'components/CreationList/constant';
import { navigateToActivityPage } from 'actions/activity';
import { selectCurrentActivity } from 'selectors/activity';
import { isPresentationActivity } from '@kritik/utils/activity';

function ViewByContainer(props: any) {
  return (
    <div className="viewby-container">
      <div className="viewby-container__children">{props.children}</div>
    </div>
  );
}

function ViewBy(props: any) {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const typeOptions = {};
  Object.values(TABLE_TYPE_OPTIONS).forEach((value) => {
    typeOptions[value] = {
      value,
      label: value,
    };
  });
  const selectedOption = typeOptions[props.tableType];
  const options = [
    {
      label: localize({ message: 'CreationList.Menu.ViewBy.Progress' }),
      value: 'Progress',
      testid: 'progress-view',
    },
    {
      label: localize({ message: 'CreationList.Menu.ViewBy.Scoring' }),
      value: 'Scoring',
      testid: 'scoring-view',
    },
    {
      label: localize({ message: 'CreationList.Menu.ViewBy.ScoreComparison' }),
      value: TABLE_TYPE_OPTIONS.SCORE_COMPARISON,
      testid: 'score-comparison',
    },
  ];

  if (!isPresentationActivity(props.activity)) {
    options.push({
      label: localize({ message: 'CreationList.Menu.ViewBy.Dispute' }),
      value: 'Dispute',
      testid: 'dispute-view',
    });
  }

  return (
    <ViewByContainer>
      <div>
        <MenuLabel
          label={<TranslatedText i18nKey="CreationList.Menu.ViewBy.Label" />}
          id="view-by"
        />
      </div>
      <div>
        <ButtonWithSelect
          id="view-by"
          value={selectedOption ? selectedOption.value : null}
          options={options}
          onChange={(e: any) => {
            if (e.target.value === TABLE_TYPE_OPTIONS.SCORE_COMPARISON) {
              searchParams.set('viewType', TABLE_TYPE_OPTIONS.SCORE_COMPARISON);
              searchParams.set('sortOrder', '-1');
              searchParams.set('sortBy', 'DIFF');
              props.setTableType(e.target.value);
              navigate(
                `/course/${props.courseId}/assignment/${props.activityId}/score-comparison?` +
                  searchParams.toString()
              );
            } else {
              props.setTableType(e.target.value);
              props.navigateToActivityPage({
                courseId: props.courseId,
                assignmentId: props.activityId,
              });
            }
          }}
          title={selectedOption ? selectedOption.value : null}
          disabled={props.disabled}
          testid="creation-list-view-by"
        />
      </div>
    </ViewByContainer>
  );
}

const mapStateToProps = (state: any) => {
  return {
    tableType: selectCreationTableViewType(state),
    courseId: state.selected.courseId,
    activityId: state.selected.assignmentId,
    activity: selectCurrentActivity(state),
  };
};

export default connect(mapStateToProps, { setTableType, navigateToActivityPage })(ViewBy);
