import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router';
import momentTZ from 'moment-timezone';

import { Button, ButtonContainer } from 'components/buttons';
import { selectAuthUser } from 'selectors/user';
import { getCourse } from 'selectors/course';
import { getAssignment } from 'selectors/activity';
import { RouterProp, withRouter } from 'utils/withRouter';
import { selectSpotlightByReference } from 'redux/spotlight';
import Tooltip from '@material-ui/core/Tooltip';
import { I18nKey, localize } from 'locales';
import { useFetchPlagiarismProbability } from 'hooks/plagiarism';
import { useIsFeatureFlagEnabled } from 'context/growthbookContext';
import NoticeBoard from 'components/layout/NoticeBoard/index';
import { trackEvent } from 'utils/userEvents';
import { useUserRoleInCourse } from 'hooks/course';
import { getActiveStatus } from '@kritik/utils/stage';
import { AuthUser } from 'app-types';
import { canSpotlightCreation } from './spotlight';
import { DisplayBoxDivider } from 'components/layout';
import { TranslatedText } from 'components/TranslatedText';
import { useCountPlagiarismCreationMatchesAndResolutionStatus } from 'hooks/creations';
import StatusLabel from 'components/Creation/StatusLabel';
import moment from 'moment';
import {
  useGetCreationFlags,
  useResolveActivityActionItem,
  useUnresolveActivityActionItem,
} from 'hooks/activityActionItems';
import { enqueueSnackbar } from 'notistack';
import GTPZeroLogo from 'images/gptzero_logo.png';

type PlagiarismCheckContentProps = {
  icon: React.ReactNode;
  children: React.ReactNode;
  title: I18nKey;
};
type FlagCreationReason = creations.$id.flags.GET.Response[0]['reason'];
const reasons: Record<FlagCreationReason, I18nKey> = {
  AttachmentOrLinkCannotBeOpened: 'FlagCreation.Reason.AttachmentOrLinkCannotBeOpened',
  PotentialPlagiarism: 'FlagCreation.Reason.PotentialPlagiarism',
  PotentialAIContent: 'FlagCreation.Reason.PotentialAIContent',
  InappropriateContent: 'FlagCreation.Reason.InappropriateContent',
  Other: 'FlagCreation.Reason.Other',
};

function PlagiarismCheckContent(p: PlagiarismCheckContentProps) {
  return (
    <div className="plagiarism-check-content" data-testid="plagiarism-check-content-box">
      <div className="plagiarism-check-content__icon">{p.icon}</div>
      <div className="plagiarism-check-content__main">
        <h5 className="plagiarism-check-content__title">
          <TranslatedText i18nKey={p.title} />
        </h5>
        {p.children}
      </div>
    </div>
  );
}

type Props = {
  authUser: AuthUser;
  course: any;
  activity: any;
  spotlight: any;
  creation: any;
  router: RouterProp;
  children: React.ReactNode;
};

function InstructorOptions(props: Props) {
  const [aiGeneratedProbabilityValues, setAiGeneratedProbability] = useState<{
    ai: number;
    human: number;
    mixed: number;
  } | null>(null);
  const navigate = useNavigate();
  const [aiGeneratedError, setAiGeneratedError] = useState<string>('');
  const [creationId, setCreationId] = useState<string | null>(null);

  const { data, isSuccess, isError, error } = useFetchPlagiarismProbability(creationId);
  const isGptZeroEnabled = useIsFeatureFlagEnabled('gpt_zero');
  const isSimilarityReportEnabled = useIsFeatureFlagEnabled('similarity_report');
  const plagiarismCreationMatchesCount = useCountPlagiarismCreationMatchesAndResolutionStatus({
    creationId: props.creation._id,
  });
  const creationFlags = useGetCreationFlags({
    creationId: props.creation._id,
  });
  const resolveActivityActionItem = useResolveActivityActionItem();
  const unresolveActivityActionItem = useUnresolveActivityActionItem();

  const showAiCheck = isGptZeroEnabled;
  const showSimilarityReport =
    isSimilarityReportEnabled && plagiarismCreationMatchesCount.data?.count > 0;
  const plagiarismResolutionStatus =
    plagiarismCreationMatchesCount.data?.plagiarismResolutionStatus;

  const showSpotlightButton = canSpotlightCreation({
    spotlight: props.spotlight,
    creation: props.creation,
    activity: props.activity,
  });

  useEffect(() => {
    if (isSuccess && Boolean(data.probability)) {
      const { ai, human, mixed } = data.probability;
      setAiGeneratedProbability({ ai, human, mixed });
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      // @ts-expect-error TS(2345) FIXME
      setAiGeneratedError(error?.response?.data?.errors?.message || 'Error processing file');
    }
  }, [isError]);

  const handleCheckPlagiarismGptZero = () => {
    setCreationId(props.creation._id);
    trackEvent('Clicked on AI-Plagiarism', props.authUser, {
      activityType: props.activity.activityType,
      activityId: props.activity._id,
      activityName: props.activity.title,
      activityStage: getActiveStatus(props.activity).name,
    });
  };

  async function resolveOrUnresolveCreationFlag(
    flag: ReturnType<typeof useGetCreationFlags>['data'][0]
  ) {
    try {
      if (flag.resolvedAt) {
        await unresolveActivityActionItem.mutateAsync({
          activityActionItemId: flag.activityActionItemId,
        });
        enqueueSnackbar({
          message: localize({
            message: 'Creation.Flag.InstructorNotice.Unresolve.Success.Message',
          }),
          title: localize({
            message: 'Creation.Flag.InstructorNotice.Unresolve.Success.Title',
          }),
          variant: 'success',
        });
      } else {
        await resolveActivityActionItem.mutateAsync({
          activityActionItemId: flag.activityActionItemId,
          note: '',
          status: 'Acknowledged',
        });
        enqueueSnackbar({
          message: localize({
            message: 'Creation.Flag.InstructorNotice.Resolve.Success.Message',
          }),
          title: localize({
            message: 'Creation.Flag.InstructorNotice.Resolve.Success.Title',
          }),
          variant: 'success',
        });
      }
    } catch (error) {
      enqueueSnackbar({
        message: localize({
          message: 'Creation.Flag.InstructorNotice.Error.Message',
        }),
        title: localize({
          message: 'Creation.Flag.InstructorNotice.Error.Title',
        }),
        variant: 'error',
      });
    }
  }

  return (
    <div>
      <ButtonContainer className="creation-options__field">
        {showSpotlightButton && (
          <Tooltip
            title={localize({ message: 'Creation.Evaluation.Tooltip.Spotlight' })}
            placement="top"
          >
            <Button
              onClick={() => {
                const url = `/course/${props.course._id}/assignment/${props.activity._id}/spotlights/create?type=Creation&referenceId=${props.creation._id}`;
                props.router.push(url);
              }}
              type="secondary"
              data-testid="add-spotlight"
            >
              <i className="fa fa-lightbulb-o button-padding-right" aria-hidden="true"></i>{' '}
              Spotlight Creation
            </Button>
          </Tooltip>
        )}
      </ButtonContainer>
      {(showAiCheck || showSimilarityReport) && (
        <>
          <DisplayBoxDivider />
          <div className="plagiarism-check">
            <div className="plagiarism-check__title-wrapper">
              <h4 className="plagiarism-check__title">
                <TranslatedText i18nKey="Plagiarism.Title" />
              </h4>
              <p className="plagiarism-check__subtitle">
                <TranslatedText i18nKey="Plagiarism.Subtitle" />
              </p>
            </div>
            {showAiCheck && (
              <PlagiarismCheckContent
                icon={<i className="fa fa-magic"></i>}
                title="Plagiarism.AI.ContentCheck.Notice.Title"
              >
                {aiGeneratedError && (
                  <NoticeBoard type="danger" title="Error">
                    {aiGeneratedError}
                  </NoticeBoard>
                )}
                {!aiGeneratedProbabilityValues && (
                  <Tooltip
                    title={localize({ message: 'Plagiarism.AI.ContentCheck.Btn.Tooltip' })}
                    placement="top"
                  >
                    <Button
                      type="secondary"
                      onClick={handleCheckPlagiarismGptZero}
                      className="plagiarism-check-content__ai-check-button"
                    >
                      <i className="fas fa-robot"></i>{' '}
                      {localize({ message: 'Plagiarism.AI.ContentCheck.Btn.Title' })}
                    </Button>
                  </Tooltip>
                )}
                {aiGeneratedProbabilityValues && (
                  <div>
                    <p>{`${Number(aiGeneratedProbabilityValues.human / 100).toLocaleString(
                      undefined,
                      {
                        style: 'percent',
                        minimumFractionDigits: 2,
                      }
                    )} ${localize({ message: 'Plagiarism.AI.ContentCheck.Notice.HumanWritten' })}`}</p>
                    <p>{`${Number(aiGeneratedProbabilityValues.ai / 100).toLocaleString(undefined, {
                      style: 'percent',
                      minimumFractionDigits: 2,
                    })} ${localize({ message: 'Plagiarism.AI.ContentCheck.Notice.AIWritten' })}`}</p>

                    <p>{`${Number(aiGeneratedProbabilityValues.mixed / 100).toLocaleString(
                      undefined,
                      {
                        style: 'percent',
                        minimumFractionDigits: 2,
                      }
                    )} ${localize({ message: 'Plagiarism.AI.ContentCheck.Notice.MixedWritten' })}`}</p>
                    <div className="plagiarism-check-content__provider">
                      <TranslatedText i18nKey="Plagiarism.AI.ContentCheck.PoweredBy" />
                      <a href="https://gptzero.me/" target="_blank">
                        <img aria-hidden="true" src={GTPZeroLogo} alt="Logo of GTP Zero" />
                      </a>
                    </div>
                  </div>
                )}
              </PlagiarismCheckContent>
            )}
            {showAiCheck && showSimilarityReport && (
              <DisplayBoxDivider className="plagiarism-check-content__divider" />
            )}
            {showSimilarityReport && (
              <PlagiarismCheckContent
                icon={<i className="fa fa-copy"></i>}
                title="Plagiarism.SimilarityReport.Title"
              >
                {plagiarismCreationMatchesCount.data.count === 0 && (
                  <p>
                    <TranslatedText i18nKey="Plagiarism.SimilarityReport.NoSimilarContentFound" />
                  </p>
                )}
                {plagiarismCreationMatchesCount.data.count > 0 && (
                  <>
                    <StatusLabel
                      label={
                        <TranslatedText i18nKey="ActionItems.IssueOptions.PotentialPlagiarismCreation" />
                      }
                      status="danger"
                      icon={<i className="fa fa-exclamation-circle" />}
                    />
                    <p>
                      <TranslatedText i18nKey="Plagiarism.SimilarityReport.SimilarContentFound" />
                    </p>
                    <DisplayBoxDivider className="plagiarism-check-content__similarity-report-divider" />
                    <ButtonContainer>
                      <Button
                        type="secondary"
                        onClick={() =>
                          navigate(
                            `/course/${props.course._id}/assignment/${props.activity._id}/reports/similarity/${props.creation._id}`
                          )
                        }
                        testid="similarity-report-details-button"
                      >
                        <TranslatedText i18nKey="Plagiarism.SimilarityReport.ReportDetails" />{' '}
                        <i className="fa fa-external-link" />
                      </Button>
                    </ButtonContainer>
                  </>
                )}
                {Boolean(plagiarismResolutionStatus) && (
                  <NoticeBoard
                    title="Potential Plagiarism Resolved"
                    testid="resolve-similarity-notice"
                  >
                    <TranslatedText
                      i18nKey="CreationSimilarityReport.Resolution.ResolvedBy"
                      values={{
                        name: plagiarismResolutionStatus.resolvedBy,
                        date: moment(plagiarismResolutionStatus.resolvedOn).format(
                          'MMM DD yyyy @ hh:mm'
                        ),
                      }}
                    />

                    {plagiarismResolutionStatus.note && (
                      <div style={{ marginTop: '5px' }}>
                        <strong>Internal notes</strong>
                        <div>{plagiarismResolutionStatus?.note}</div>
                      </div>
                    )}
                  </NoticeBoard>
                )}
              </PlagiarismCheckContent>
            )}
          </div>
        </>
      )}
      {creationFlags.data?.length > 0 && (
        <>
          <DisplayBoxDivider />
          {creationFlags.data.map((f) => {
            return (
              <NoticeBoard
                testid={`flag-creation-notice-${f.activityActionItemId}`}
                type={f.resolvedAt ? 'information' : 'danger'}
                title={
                  <TranslatedText
                    i18nKey="Creation.Flag.InstructorNotice.Title"
                    values={{ user: f.flaggedByUserName }}
                  />
                }
              >
                <p>
                  <TranslatedText
                    i18nKey="Creation.Flag.ExistingFlag.Reason"
                    values={{
                      reason: localize({ message: reasons[f.reason] }),
                    }}
                  />
                </p>

                <p>
                  <TranslatedText
                    i18nKey="Creation.Flag.ExistingFlag.Date"
                    values={{
                      date: momentTZ(f.createdAt).format('MMM DD yyyy @ hh:mm'),
                    }}
                  />
                </p>
                <p>
                  <TranslatedText
                    i18nKey="Creation.Flag.ExistingFlag.Explanation"
                    values={{
                      explanation: f.explanation,
                    }}
                  />
                </p>
                <Button
                  testid="flag-creation-button"
                  type="secondary"
                  onClick={async () => await resolveOrUnresolveCreationFlag(f)}
                >
                  <TranslatedText
                    i18nKey={
                      f.resolvedAt
                        ? 'Creation.Flag.InstructorNotice.Button.Unresolve'
                        : 'Creation.Flag.InstructorNotice.Button.Resolve'
                    }
                  />
                </Button>
              </NoticeBoard>
            );
          })}
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state: any, ownProps: any) => {
  const creationId = ownProps.creation ? ownProps.creation._id : null;
  return {
    authUser: selectAuthUser(state),
    course: getCourse(state),
    activity: getAssignment(state),
    spotlight: selectSpotlightByReference(creationId, state),
  };
};

const CreationInstructorOptions = withRouter(connect(mapStateToProps)(InstructorOptions));

export { CreationInstructorOptions };
