import { connect } from 'react-redux';
import { getAssignment } from 'selectors/activity';
import { Field } from 'react-final-form';
import validators from 'components/ActivityEdit/Details/Fields/validators';
import * as statusUtil from '@kritik/utils/stage';
import FormFieldTitle from 'components/core/form/Title';
import FormFieldInput from 'components/core/form/FieldInput';
import { ACTIVITY_TYPES } from '@kritik/constants/activity';
import NumberInput from 'components/core/input/Number';
import { FIELD_NAME } from 'components/ActivityEdit/AdvancedOptions/Fields/NumEvalsToAssign/constants';
import InlineInformation from 'components/layout/InlineInformation';
import { localize } from 'locales/index';
import FormInfo from 'components/core/form/FieldInfo';
import { TranslatedText } from 'components/TranslatedText';

const NumEvalsToDo = (props: any) => {
  const sanitizeNumEvalsToAssign = (value: any) => {
    const min = 1;
    const max = 20;
    if (Number(value) < min) {
      return min;
    }
    if (Number(value) > max) {
      return max;
    }
    return Number(value);
  };

  const renderWithinGroupInfo = () => {
    if (props.values.activityType !== ACTIVITY_TYPES.WITHIN_GROUP) {
      return;
    }
    return (
      <p className="num-evals-to-assign__withingroup">
        <TranslatedText
          i18nKey={
            props.values.selfEvaluate
              ? 'NumEvalsToDo.WithinGroupInfoWithSelf'
              : 'NumEvalsToDo.WithinGroupInfoWithoutSelf'
          }
        />
      </p>
    );
  };

  const renderHeader = () => {
    return (
      <>
        <FormFieldTitle label={localize({ message: 'NumEvalsToDo.Title' })} />
        {renderWithinGroupInfo()}
      </>
    );
  };

  const isDisabled = () => {
    if (props.values.settings.isDuplicating || !props.activity) {
      return false;
    }
    return statusUtil.isEvaluateOrLater(props.activity);
  };
  const renderLowWarning = () => {
    return (
      <InlineInformation type="warning">
        <TranslatedText i18nKey="NumEvalsToDo.LowWarning" />
      </InlineInformation>
    );
  };
  return (
    <Field
      name={FIELD_NAME}
      validate={validators.composeValidators(
        validators.isRequired,
        validators.minValue(1),
        validators.maxValue(20)
      )}
    >
      {({ input, meta }) => {
        return (
          <>
            {renderHeader()}
            {props.values.activityType !== ACTIVITY_TYPES.WITHIN_GROUP && (
              <FormFieldInput>
                <NumberInput
                  className="not-bold"
                  name={FIELD_NAME}
                  value={input.value}
                  onChange={(e: any) => {
                    return input.onChange(sanitizeNumEvalsToAssign(e.target.value));
                  }}
                  disabled={isDisabled()}
                  min={1}
                  max={20}
                  testid="num-of-evals-input"
                  id="num-of-evals-input"
                />
              </FormFieldInput>
            )}
            <FormInfo label={localize({ message: 'Label.FormControl.NumberOfEvaluations' })} />
            {input.value < 3 && renderLowWarning()}
          </>
        );
      }}
    </Field>
  );
};

const mapStateToProps = (state: any) => {
  return {
    activity: getAssignment(state, state.selected.assignmentId),
  };
};

export default connect(mapStateToProps, {})(NumEvalsToDo);
