import React, { useState } from 'react';
import { connect } from 'react-redux';
import ButtonWithSelect from 'components/buttons/ButtonWithSelect';
import { openInfoModal, closeInfoModal } from 'actions/modals';
import Button from 'components/buttons/Button';
import ButtonContainer from 'components/buttons/ButtonContainer';
import {
  cancelExportCourseCreations,
  exportCourseCreations,
  navigateToCourseDetailsEditPage,
  archiveCourse,
} from 'actions/courses';
import StudentPreferences from 'containers/Course/partials/StudentPreferences';
import { getStudentFromUser } from 'selectors/student';
import DownloadGradebookModal from 'components/DownloadGradebookModal';
import DownloadCreationsModal from 'components/DownloadCreationsModal';
import classNames from 'classnames';
import AvatarDisplay from 'components/layout/AvatarDisplay';
import { localize } from 'locales';
import { withRouter } from 'utils/withRouter';
import DuplicateDialog from 'components/Course/List/DuplicateDialog';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import { InlineInformation } from 'components/layout';
import {
  deleteCourseMutation,
  duplicateCourseMutation,
  useStudentInfo,
  useUserRoleInCourse,
} from 'hooks/course';

const DOWNLOAD_GRADEBOOK = localize({ message: 'CourseDetails.Actions.DownloadGradebook' });
const DOWNLOAD_CREATIONS = localize({ message: 'CourseDetails.Actions.DownloadCreations' });
const DUPLICATE = localize({ message: 'CourseCard.Items.Duplicate' });
const ARCHIVE = localize({ message: 'CourseCard.Items.Archive' });
const DELETE = localize({ message: 'CourseCard.Items.Delete' });

const buttonOptions = [
  {
    value: DOWNLOAD_GRADEBOOK,
    disabled: false,
    label: DOWNLOAD_GRADEBOOK,
    testid: 'download-gradebook-item-menu',
  },
  {
    value: DOWNLOAD_CREATIONS,
    disabled: false,
    label: DOWNLOAD_CREATIONS,
  },
  {
    value: DUPLICATE,
    disabled: false,
    label: DUPLICATE,
  },
  {
    value: ARCHIVE,
    disabled: false,
    label: ARCHIVE,
    testid: 'archive-course-item-menu',
  },
  {
    value: DELETE,
    disabled: false,
    label: DELETE,
    testid: 'delete-course-item-menu',
  },
];

const CourseDetailsPageHeader = ({
  className,
  title,
  separator,
  children,
  testid,
  institutionName,
  user,
}: any) => {
  const headerClasses = classNames(
    'course-details-page-header',
    {
      'course-details-page-header--separator': separator,
    },
    className
  );

  return (
    <div className={headerClasses}>
      <div className="page-title">
        <p
          className="course-details__institution-title"
          data-testid={testid}
          role="heading"
          aria-label={title}
        >
          {institutionName}
        </p>
        <h1 className="course-details__course-title" data-testid={testid}>
          {title}
        </h1>
        <div className="course-details__section" tabIndex={0}>
          <div className="course-details__instructor">
            <AvatarDisplay
              user={user}
              size="60px"
              anonymous={false}
              showBorder={false}
              hoverable={false}
            />
            <div className="course-details__user-info">
              <div className="course-details__user-name">{user.profile.name}</div>
              <div className="course-details__user-email">{user.email}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="course-details-page-header__options">{children}</div>
    </div>
  );
};

function CourseHeader(props: any) {
  const [isEditStudentPreference, setIsEditStudentPreference] = useState(false);
  const [isGradebookDownloadConfirmed, setIsGradebookDownloadConfirmed] = useState(false);
  const [isDownloadCreationsOpen, setIsDownloadCreationsOpen] = useState(false);
  const [showDuplicateDialog, setShowDuplicateDialog] = React.useState(false);
  const [isDeletingCourse, setIsDeletingCourse] = React.useState(false);
  const duplicateCourse = duplicateCourseMutation(props.user);
  const { isInstructorInCourse, isStudentInCourse, isOwnerInCourse } = useUserRoleInCourse();
  const { data: student } = useStudentInfo({
    courseId: props.router.params.courseId,
    isStudent: isStudentInCourse,
  });
  const deleteCourseMutate = deleteCourseMutation({
    onError: () => {
      setIsDeletingCourse(false);
    },
  });

  React.useEffect(() => {
    if (duplicateCourse.isSuccess) {
      props.router.push(`/`);
    }

    if (deleteCourseMutate.isSuccess) {
      props.router.push(`/`);
    }
  }, [duplicateCourse.isSuccess, deleteCourseMutate.isSuccess]);

  const filteredButtonOptions = buttonOptions.filter((option) => {
    if (option.value === DOWNLOAD_CREATIONS) {
      return isInstructorInCourse;
    }
    if (option.value === ARCHIVE) {
      return isOwnerInCourse;
    }
    if (option.value === DELETE) {
      return isOwnerInCourse;
    }
    return true;
  });

  const renderStudentPreferenceModal = () => {
    return (
      <StudentPreferences
        isOpen={isEditStudentPreference}
        onClose={() => {
          setIsEditStudentPreference(false);
        }}
        user={props.user.authUser}
        student={student}
        onSubmit={() => {
          setIsEditStudentPreference(false);
        }}
      />
    );
  };

  const toggleDuplicateDialog = () => {
    setShowDuplicateDialog(!showDuplicateDialog);
  };

  const handleDuplicateCourse = (data: any) => {
    duplicateCourse.mutate(data);

    toggleDuplicateDialog();
  };

  const archiveCourse = () => {
    props.archiveCourse({
      courseId: props.course._id,
    });
  };

  const toggleDeleteConfirmation = () => {
    setIsDeletingCourse(!isDeletingCourse);
  };

  const deleteCourse = () => {
    deleteCourseMutate.mutate(props.router.params.courseId);
  };

  const renderConfirmCourseDeletion = () => {
    return (
      <Dialog
        open={isDeletingCourse}
        onClose={() => {
          return toggleDeleteConfirmation();
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Are you sure you want to delete this course?
        </DialogTitle>
        <DialogContent>
          <InlineInformation type="danger" title="Deleting this course cannot be undone" />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              return toggleDeleteConfirmation();
            }}
            type="secondary"
            autoFocus
          >
            Cancel
          </Button>
          <Button
            onClick={() => {
              return deleteCourse();
            }}
            type="primary"
          >
            Delete Course
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const handleButtonSelect = (e: any) => {
    const option = e.target.value;
    switch (option) {
      case DOWNLOAD_GRADEBOOK:
        return setIsGradebookDownloadConfirmed(true);
      case DOWNLOAD_CREATIONS:
        return setIsDownloadCreationsOpen(true);
      case DUPLICATE:
        return setShowDuplicateDialog(!showDuplicateDialog);
      case ARCHIVE:
        return archiveCourse();
      case DELETE:
        return setIsDeletingCourse(!isDeletingCourse);
      default:
        return null;
    }
  };

  const renderActions = () => {
    if (isInstructorInCourse) {
      return (
        <ButtonContainer>
          <ButtonWithSelect
            type="secondary"
            title="More Actions"
            options={filteredButtonOptions}
            onChange={handleButtonSelect}
            testid="course-details-page_action"
            position="right"
            className="action_container"
          />

          <Button
            type="primary"
            onClick={() => props.navigateToCourseDetailsEditPage(props.course._id)}
            testid="edit-course-details"
          >
            {localize({ message: 'CourseDetails.Actions.EditDetails' })}
          </Button>
        </ButtonContainer>
      );
    }
    return (
      <Button
        type="primary"
        onClick={() => {
          return setIsEditStudentPreference(true);
        }}
        label={localize({ message: 'Button.Label.EditCoursePreferences' })}
      >
        {localize({ message: 'CourseDetails.Actions.Preferences' })}
      </Button>
    );
  };

  return (
    <React.Fragment>
      <CourseDetailsPageHeader
        title={props.course.title}
        institutionName={props.user.institution?.name}
        user={props.course.user}
        separator
      >
        {renderActions()}
      </CourseDetailsPageHeader>
      {renderStudentPreferenceModal()}
      <DownloadGradebookModal
        course={props.course}
        isOpen={isGradebookDownloadConfirmed}
        setIsOpen={setIsGradebookDownloadConfirmed}
      />
      <DownloadCreationsModal
        course={props.course}
        isOpen={isDownloadCreationsOpen}
        setIsOpen={setIsDownloadCreationsOpen}
      />
      {isDeletingCourse && renderConfirmCourseDeletion()}
      {showDuplicateDialog && (
        <DuplicateDialog
          open={showDuplicateDialog}
          onClose={() => {
            return toggleDuplicateDialog();
          }}
          course={(props as any).course}
          onSubmit={handleDuplicateCourse}
        />
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
    currentStudent: getStudentFromUser(state, state.selected.courseId),
  };
};

export default withRouter(
  connect(mapStateToProps, {
    openInfoModal,
    closeInfoModal,
    cancelExportCourseCreations,
    exportCourseCreations,
    navigateToCourseDetailsEditPage,
    archiveCourse,
  })(CourseHeader)
);
