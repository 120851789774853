import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import * as _ from 'lodash-es';
import { Form } from 'react-final-form';
import { getCourses } from 'actions/courses';
import Button from 'components/buttons/Button';
import PageHeader from 'components/layout/PageHeader';
import InlineInformation from 'components/layout/InlineInformation';
import NoticeBoard from 'components/layout/NoticeBoard';
import {
  getAssignment,
  updateAssignment,
  updateAssignmentSchedule,
  createGroupActivitySubmissions,
  navigateToActivityPage,
} from 'actions/activity';
import { getCourse } from 'selectors/course';
import { assignmentSelector } from 'selectors';
import { selectIsDifferentOffSet, selectTimeZone } from 'selectors/user';
import { selectAssignment, selectCourse } from 'actions/select';
import { setIsDifferentOffSet } from 'actions/users';
import {
  getActiveStatus,
  getCreationStage,
  getEvaluationStage,
  getEvaluationGracePeriodStage,
  getFeedbackStage,
  getCreationGracePeriodStage,
  getNumberOfGroupsParticipatingInCreateStage,
  isCreateOrLater,
} from '@kritik/utils/stage';
import { UPDATE_ASSIGNMENT_SCHEDULE } from 'types';
import ActivityTypeLargeDisplay from 'components/Assignment/Type';
import FormField from 'components/core/form/Field';
import FormSubmitButtons from 'components/core/form/SubmitButtons';
import GroupSetSelect from 'components/ActivityEdit/GroupOptions/GroupSetSelect';
import { getAllGroupSets } from 'actions/groups';
import { getActivityGroupSet } from 'components/ActivityEdit/Group/utils';
import PageContainer from 'components/layout/PageContainer';
import { getGroupSetList } from 'selectors/group';
import * as statusUtil from '@kritik/utils/stage';
import { TranslatedText } from 'components/TranslatedText';

import * as AssignmentUtil from '@kritik/utils/activity';

import { resetSchedule, setErrorPastDate, getSchedulingTemplate } from 'redux/schedule/actions';

import ScheduleSelectors from 'redux/schedule/selectors';

import ActivitySelectors from 'redux/activity/select';
import Scheduler from './Scheduler';
import CalibrationSchedule from './CalibrationSchedule';
import localUtils from './utils';
import { isPresentationActivity } from '@kritik/utils/activity';
import { localize } from 'locales/index';

const mapStateToProps = (state: any) => {
  return {
    entities: state.entities,
    user: state.user,
    course: getCourse(state),
    assignmentId: state.selected.assignmentId,
    assignment: assignmentSelector.getAssignment(state, state.selected.assignmentId),
    scheduleBusy: state.async[UPDATE_ASSIGNMENT_SCHEDULE].busy,
    scheduleSuccess: state.async[UPDATE_ASSIGNMENT_SCHEDULE].success,
    scheduleError: state.async[UPDATE_ASSIGNMENT_SCHEDULE].error,
    timeZone: selectTimeZone(state),
    isDifferentOffSet: selectIsDifferentOffSet(state),
    groupSetList: getGroupSetList(state),
    schedule: ScheduleSelectors.selectSchedule(state),
    pastDateError: ScheduleSelectors.selectPastDateError(state),
    getActivityAsyncState: ActivitySelectors.selectGetActivityState(state),
    getScheduleTemplateState: ScheduleSelectors.selectGetSchedulingTemplateState(state),
  };
};

type ScheduleState = any;

class Schedule extends Component<{}, ScheduleState> {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      groupSet: null,
      groupSetsLoaded: false,
      activeStatus: null,
    };
  }

  initState = () => {
    // @ts-expect-error TS(2339) FIXME: Property 'router' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { router, assignment } = this.props;

    if (
      isPresentationActivity((this.props as any).assignment) &&
      getNumberOfGroupsParticipatingInCreateStage((this.props as any).assignment) < 1
    ) {
      (this.props as any).router.push(
        `/course/${router.params.courseId}/assignment/${router.params.assignmentId}`
      );
    }

    (this.props as any).getSchedulingTemplate(assignment.course._id || assignment.course);

    const activeStatus = getActiveStatus(assignment);
    if (this.isBetweenGroupOrWithinGroupActivity()) {
      (this.props as any).getAllGroupSets({ courseId: router.params.courseId }).then(() => {
        const { groupSetList, assignment } = this.props as any;
        this.setState({
          groupSet: getActivityGroupSet(groupSetList, assignment),
          groupSetsLoaded: true,
        });
      });
    }

    this.setState({ activeStatus });
  };

  componentDidMount() {
    // @ts-expect-error TS(2339) FIXME: Property 'router' does not exist on type 'Readonly... Remove this comment to see the full error message
    const { router } = this.props;
    this.setIsDifferentOffSet();
    (this.props as any).getAssignment(
      { id: router.params.assignmentId },
      {
        callback: this.initState,
      }
    );

    if (!(this.props as any).assignment) {
      (this.props as any).selectAssignment(router.params.assignmentId);
      (this.props as any).selectCourse(router.params.courseId);
      (this.props as any).getCourses();
    }
  }

  componentDidUpdate(prevProps: {}) {
    if ((this.props as any).scheduleSuccess && !(prevProps as any).scheduleSuccess) {
      setTimeout(() => {
        (this.props as any).navigateToActivityPage({
          courseId: (this.props as any).assignment.course,
          assignmentId: (this.props as any).assignment._id,
        });
      }, 2000);
    }
  }

  componentWillUnmount() {
    (this.props as any).resetSchedule();
  }

  setIsDifferentOffSet = () => {
    const now = moment.utc();
    if ((this.props as any).timeZone) {
      // parse time offsets based on client time zone  & ins time zone id
      // @ts-expect-error TS(2345) FIXME: Argument of type 'Moment' is not assignable to par... Remove this comment to see the full error message
      const clientOffset = moment.tz.zone(moment.tz.guess(true)).utcOffset(now);
      // @ts-expect-error TS(2345) FIXME: Argument of type 'Moment' is not assignable to par... Remove this comment to see the full error message
      const insOffset = moment.tz.zone((this.props as any).timeZone).utcOffset(now);
      (this.props as any).setIsDifferentOffSet({
        isDifferentOffSet: clientOffset !== insOffset,
      });
    }
  };

  isBetweenGroupOrWithinGroupActivity() {
    return (
      AssignmentUtil.isGroupAssignment((this.props as any).assignment) ||
      AssignmentUtil.isWithinGroupActivity((this.props as any).assignment)
    );
  }

  scheduleCalibrationActivity() {
    // @ts-expect-error TS(2339) FIXME: Property 'schedule' does not exist on type 'Readon... Remove this comment to see the full error message
    const { schedule } = this.props;
    const pastDateError = localUtils.validateIsPastDate(schedule, (this.props as any).assignment);
    if (pastDateError) {
      return (this.props as any).setErrorPastDate(pastDateError);
    }
    const create = getCreationStage((this.props as any).assignment);
    const evaluate = getEvaluationStage((this.props as any).assignment);
    const feedback = getFeedbackStage((this.props as any).assignment);
    const data = {
      id: (this.props as any).assignment._id,
      course: (this.props as any).course._id,
      assignmentId: (this.props as any).assignmentId,
      activityType: (this.props as any).assignment.activityType,
      statusList: [
        {
          _id: create._id,
          active: false,
          name: 'Create',
          startDate: null,
          endDate: null,
        },
        {
          _id: evaluate._id,
          active: evaluate.active,
          name: 'Evaluate',
          startDate: schedule.startDate,
          endDate: schedule.evaluationEndDate,
        },
        {
          _id: feedback._id,
          active: false,
          name: 'Feedback',
          startDate: null,
          endDate: null,
        },
      ],
    };

    (this.props as any).updateAssignmentSchedule(data).catch((err: any) => {
      return this.setState({
        error: 'There was an issue scheduling your activity. Please contact support.',
      });
    });
  }

  isValidSchedule() {
    // @ts-expect-error TS(2339) FIXME: Property 'schedule' does not exist on type 'Readon... Remove this comment to see the full error message
    const { schedule } = this.props;
    if (AssignmentUtil.isCalibrationActivity((this.props as any).assignment)) {
      return localUtils.isValidCalibrationSchedule(schedule);
    }
    const currentDate = Date.parse((new Date(), (this.props as any).timeZone));
    return localUtils.isValidSchedule({
      schedule,
      currentDate,
      activeStatus: this.state.activeStatus,
    });
  }

  async onSubmit() {
    // @ts-expect-error TS(2339) FIXME: Property 'assignment' does not exist on type 'Read... Remove this comment to see the full error message
    const { assignment, schedule } = this.props;
    const pastDateError = localUtils.validateIsPastDate(schedule, assignment);
    if (pastDateError) {
      return (this.props as any).setErrorPastDate(pastDateError);
    }

    // gracePeriod here is processing1 in database
    const create = getCreationStage(assignment);
    const gracePeriod = getCreationGracePeriodStage(assignment);
    const evaluate = getEvaluationStage(assignment);
    const evaluateGracePeriod = getEvaluationGracePeriodStage(assignment);
    const feedback = getFeedbackStage(assignment);

    const {
      creationEndDate,
      evaluationEndDate,
      feedbackEndDate,
      startDate: creationStartDate,
    } = schedule;

    const data = {
      id: assignment._id,
      course: (this.props as any).course._id,
      assignmentId: (this.props as any).assignmentId,
      activityType: assignment.activityType,
      statusList: [
        {
          _id: create._id,
          active: create.active,
          name: create.name,
          startDate: creationStartDate,
          endDate: creationEndDate,
        },
      ],
    };

    let evaluationStartDate = moment(creationEndDate).add(1, 'seconds').toDate();

    if (schedule.gracePeriodEndDate) {
      // if prof enable grace period we insert grace period before evaluation
      const { gracePeriodEndDate } = schedule;
      const gracePeriodStartDate = moment(creationEndDate).add(1, 'seconds').toDate();

      data.statusList.push({
        _id: gracePeriod._id,
        active: gracePeriod.active,
        name: gracePeriod.name,
        startDate: gracePeriodStartDate,
        endDate: moment(gracePeriodEndDate).add(1, 'seconds').toDate(),
      });

      evaluationStartDate = moment(gracePeriodEndDate).add(1, 'minutes').toDate();
    }

    data.statusList.push({
      _id: evaluate._id,
      active: evaluate.active,
      name: evaluate.name,
      startDate: evaluationStartDate,
      endDate: evaluationEndDate,
    });

    let feedbackStartDate = moment(evaluationEndDate).add(1, 'minutes').toDate();
    if (schedule.evaluationGracePeriodEndDate) {
      const { evaluationGracePeriodEndDate } = schedule;

      data.statusList.push({
        _id: evaluateGracePeriod._id,
        active: evaluateGracePeriod.active,
        name: evaluateGracePeriod.name,
        startDate: moment(evaluationEndDate).add(1, 'seconds').toDate(),
        endDate: moment(evaluationGracePeriodEndDate).add(1, 'seconds').toDate(),
      });

      feedbackStartDate = moment(evaluationGracePeriodEndDate).add(1, 'seconds').toDate();
    }

    data.statusList.push({
      _id: feedback._id,
      active: feedback.active,
      name: feedback.name,
      startDate: feedbackStartDate,
      endDate: feedbackEndDate,
    });

    if (
      this.isBetweenGroupOrWithinGroupActivity() &&
      !isCreateOrLater((this.props as any).assignment)
    ) {
      const newGroupSetId = (this.props as any).groupSetList.find(
        (g) => g._id === this.state.groupSet
      )._id;

      if (newGroupSetId) {
        const updateData = {
          _id: assignment._id,
          groupSetId: newGroupSetId,
          activityType: (this.props as any).assignment.activityType,
          isGroupSetUpdate: true,
        };
        try {
          await (this.props as any).updateAssignment(updateData);
          (this.props as any).updateAssignmentSchedule(data);
        } catch (err) {
          return this.setState({
            error:
              err.errors || 'There was an issue scheduling your activity. Please contact support.',
          });
        }
        return;
      }
    }
    (this.props as any).updateAssignmentSchedule(data).catch((err) => {
      return this.setState({
        error: err.errors || 'There was an issue scheduling your activity. Please contact support.',
      });
    });
  }

  cancelScheduling() {
    (this.props as any).navigateToActivityPage({
      courseId: (this.props as any).assignment.course,
      assignmentId: (this.props as any).assignment._id,
    });
  }

  renderTimeZoneOffSetWarning = () => {
    const title = `All Schedule dates and time are in ${(this.props as any).timeZone} time zone`;
    return (
      <div className="schedule-timezone-warning">
        <NoticeBoard type="caution" title={title}>
          We detected that you are currently not in the same time zone as your institution. All
          scheduled dates and times use the <i>{(this.props as any).timeZone}</i> time zone.
        </NoticeBoard>
      </div>
    );
  };

  setGroupSet(groupSet: any) {
    this.setState({ groupSet });
  }

  renderGroupSetSelect() {
    if (
      !(this.props as any).assignment ||
      !this.state.groupSetsLoaded ||
      !this.isBetweenGroupOrWithinGroupActivity()
    ) {
      return null;
    }
    // @ts-expect-error TS(2339) FIXME: Property 'assignment' does not exist on type 'Read... Remove this comment to see the full error message
    const { assignment } = this.props;
    const settings = {
      isDuplicating: false,
      activity: assignment,
    };
    const { groupSet } = this.state;

    /**
     * GroupSetSelect component is using `useForm` inside of it,
     * so we need to wrap it in a <Form/> component.
     */
    return (
      <Form onSubmit={() => undefined}>
        {() => (
          <GroupSetSelect
            isScheduling
            settings={settings}
            groupSet={groupSet}
            setGroupSet={(gs: any) => {
              return this.setGroupSet(gs);
            }}
          />
        )}
      </Form>
    );
  }

  renderErrorMessage = (testid: any, errorMessage: any) => {
    if (!errorMessage) {
      return null;
    }
    return (
      <InlineInformation testid={testid} type="danger">
        {errorMessage}
      </InlineInformation>
    );
  };

  renderGroupError = () => {
    if (
      !this.isBetweenGroupOrWithinGroupActivity() ||
      !(this.props as any).assignment ||
      !(this.props as any).groupSetList ||
      !this.state.groupSetsLoaded
    ) {
      return null;
    }
    if ((this.props as any).groupSetList.find((g) => g._id === this.state.groupSet)) {
      return null;
    }

    if (statusUtil.isCreateOrLater((this.props as any).assignment)) {
      return null;
    }

    return this.renderErrorMessage(
      'group-set-error',
      'Selecting a Group Set is required in order to schedule this activity'
    );
  };

  renderCalibrationError = () => {
    if (
      !AssignmentUtil.isCalibrationActivity((this.props as any).assignment) ||
      !(this.props as any).course
    ) {
      return null;
    }

    if (!this.isGradedCalibrationCreations()) {
      return (
        <InlineInformation testid="calibration-creations-error" type="danger">
          {localize({ message: 'Activity.CalibrationActivity.CannotSchedule.NotGraded1' })}
          <span data-testid="edit-activity">
            <Link
              to={`/course/${(this.props as any).course._id}/assignment/${
                (this.props as any).assignmentId
              }/edit-assignment`}
            >
              here
            </Link>
          </span>
          {localize({ message: 'Activity.CalibrationActivity.CannotSchedule.NotGraded2' })}
        </InlineInformation>
      );
    }

    if (this.isValidCalibrationCreations()) {
      return null;
    }

    return (
      <InlineInformation testid="calibration-creations-error" type="danger">
        Oops, looks like this calibration activity is missing creations. Please click{' '}
        <span data-testid="edit-activity">
          <Link
            to={`/course/${(this.props as any).course._id}/assignment/${
              (this.props as any).assignmentId
            }/edit-assignment`}
          >
            here
          </Link>{' '}
        </span>
        to add creations.
      </InlineInformation>
    );
  };

  isValidGroups = () => {
    if (!this.isBetweenGroupOrWithinGroupActivity() || !(this.props as any).assignment) {
      return true;
    }
    if (statusUtil.isCreateOrLater((this.props as any).assignment)) {
      return true;
    }
    return !!(this.props as any).groupSetList.find((g) => g._id === this.state.groupSet);
  };

  isValidCalibrationCreations = () => {
    if (!AssignmentUtil.isCalibrationActivity((this.props as any).assignment)) {
      return true;
    }
    const { calibrationCreations } = (this.props as any).assignment;
    if (!calibrationCreations.includes(null) && calibrationCreations.length === 3) {
      return true;
    }
    return false;
  };

  isGradedCalibrationCreations = () => {
    if (!AssignmentUtil.isCalibrationActivity((this.props as any).assignment)) {
      return true;
    }
    const { calibrationCreations } = (this.props as any).assignment;
    const notGradedCreations = calibrationCreations.filter((creation) => {
      return !!creation && creation.marks.length === 0;
    });
    return notGradedCreations.length === 0;
  };

  handleScheduleActivityClick = () => {
    if (AssignmentUtil.isCalibrationActivity((this.props as any).assignment)) {
      this.scheduleCalibrationActivity();
    } else {
      this.onSubmit();
    }
  };

  render() {
    if (
      !(this.props as any).assignment ||
      (this.props as any).getActivityAsyncState.busy ||
      (this.props as any).getScheduleTemplateState.busy
    ) {
      return null;
    }

    const invalid =
      !this.isValidSchedule() ||
      !this.isValidGroups() ||
      !this.isValidCalibrationCreations() ||
      !this.isGradedCalibrationCreations();
    const hasPastDateError = !_.isEmpty((this.props as any).pastDateError);

    return (
      <PageContainer>
        <PageHeader title={<TranslatedText i18nKey="Activity.Schedule.PageHeader" />} />
        <FormField>
          <ActivityTypeLargeDisplay type={(this.props as any).assignment.activityType} />
        </FormField>
        {this.renderGroupSetSelect()}
        <div className="schedule-errors-secondary">{this.renderGroupError()}</div>
        <div className="schedule-errors-secondary">{this.renderCalibrationError()}</div>
        <FormField>
          {(this.props as any).isDifferentOffSet && this.renderTimeZoneOffSetWarning()}
          {AssignmentUtil.isCalibrationActivity((this.props as any).assignment) ? (
            <CalibrationSchedule activity={(this.props as any).assignment} />
          ) : (
            <Scheduler
              activity={(this.props as any).assignment}
              resetErrors={() => (this.props as any).setErrorPastDate(null)}
            />
          )}
          <div className="schedule-errors-containter">
            <div className="schedule-errors-primary">
              {(!this.isValidSchedule() || hasPastDateError) && (
                <>
                  {this.renderErrorMessage(
                    'invalid-dates-error',
                    localize({ message: 'Activity.ScheduleErrorMessage' })
                  )}
                </>
              )}
            </div>
            <div className="schedule-errors-secondary">
              {this.renderErrorMessage('state-error', this.state.error)}
            </div>
          </div>
        </FormField>
        <FormSubmitButtons>
          <Button
            data-testid="schedule-activity-submit"
            type="primary"
            unavailable={invalid}
            disabled={invalid}
            onClick={this.handleScheduleActivityClick}
            loading={(this.props as any).scheduleBusy}
            success={(this.props as any).scheduleSuccess}
          >
            Schedule Activity
          </Button>
          <Button
            data-testid="schedule-activity-cancel"
            type="secondary"
            onClick={() => {
              return this.cancelScheduling();
            }}
          >
            Cancel
          </Button>
        </FormSubmitButtons>
      </PageContainer>
    );
  }
}

export default withRouter(
  connect(mapStateToProps, {
    getAssignment,
    updateAssignmentSchedule,
    selectCourse,
    selectAssignment,
    getCourses,
    updateAssignment,
    createGroupActivitySubmissions,
    setIsDifferentOffSet,
    getAllGroupSets,
    resetSchedule,
    setErrorPastDate,
    getSchedulingTemplate,
    navigateToActivityPage,
  })(Schedule)
);
