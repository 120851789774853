import { connect } from 'react-redux';
import { TranslatedText } from 'components/TranslatedText';
import { localize } from 'locales/index';

const NoInstitutionWarning = (props: any) => {
  if (
    !props.user.authenticated ||
    props.user?.authUser?.institution ||
    !props.user?.authUser?.permissions.includes('canCreateCourse')
  ) {
    return null;
  }

  return (
    <div className="no-institution-warning-container">
      <i className="fa fa-info-circle" />
      <div className="no-institution-warning">
        <h6 className="no-institution-warning__title">
          <TranslatedText i18nKey="NoInstitutionWarning.Title" />
        </h6>
        <TranslatedText i18nKey="NoInstitutionWarning.Content" />
      </div>
    </div>
  );
};

export default connect((state: any) => ({
  user: state.user,
}))(NoInstitutionWarning);
