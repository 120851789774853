import React, { useState, createRef } from 'react';
import { connect } from 'react-redux';
import Button from 'components/buttons/Button';
import ButtonContainer from 'components/buttons/ButtonContainer';
import {
  closeUploadView,
  selectIsUploadingCsvFile,
  selectNotFoundStudents,
  setNotAddedStudents,
  generateFromCSV,
} from 'redux/groupManager';
import { PageHeader } from 'components/layout';
import TextInput from 'components/core/form/TextField';
import FormSubmitButtons from 'components/core/form/SubmitButtons';
import FormField from 'components/core/form/Field';
import FormFieldLabel from 'components/core/form/FieldLabel';
import Typography from 'components/core/Typography';
import VerticalList from 'components/layout/VerticalList';
import FileList from 'components/layout/AttachmentManager/FileList';
import PageContainer from 'components/layout/PageContainer';
import NoticeBoard from 'components/layout/NoticeBoard/index';
import { TranslatedText } from 'components/TranslatedText';

function CsvUploadView(props: any) {
  const [groupSetName, setGroupSetName] = useState('');
  const [selectedFile, setSelectedFile] = useState([]);
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [groupGenerationError, setGroupGenerationError] = useState(null);
  const inputRef = createRef();

  const handleUploadGroupSet = async () => {
    setGroupGenerationError('');
    const formData = new FormData();
    formData.append('file[]', selectedFile[0], (selectedFile[0] as any).name);
    formData.append('groupSetName', groupSetName);
    formData.append('courseId', props.courseId);
    (formData as any).allowedExtensions = 'text/csv';
    await props
      .generateFromCSV({
        formData,
      })
      .then((res: any) => {
        if (res?.error?.response?.data?.validationErrors) {
          setGroupGenerationError(
            <div>
              {(res.error.response.data.validationErrors as any).map((error: any) => {
                return <p>{error.message}</p>;
              })}
            </div>
          );
        }
      });
  };

  const handleGroupNameChange = (e: any) => {
    setGroupSetName(e.target.value);
  };

  const toggleFileSelection = () => {
    (inputRef as any).current.click();
  };

  const handleNewFile = (e: any) => {
    setSelectedFile([e.target.files[0]]);
    setIsFilePicked(true);
  };

  const canUpload = () => {
    return !(groupSetName.length !== 0 && isFilePicked === true);
  };

  const handleCloseUploadView = () => {
    setIsFilePicked(false);
    setSelectedFile(null);
    props.setNotAddedStudents([]);
    props.closeUploadView();
  };

  const handleRemoveFile = () => {
    setSelectedFile([]);
    setIsFilePicked(false);
    setGroupGenerationError('');
  };

  const renderFileView = () => {
    if (selectedFile.length > 0) {
      return <FileList files={selectedFile} onRemoveFile={handleRemoveFile} />;
    }

    return (
      <React.Fragment>
        <FormFieldLabel label="Select group set file to upload" />
        <ButtonContainer>
          <Button type="secondary" onClick={toggleFileSelection}>
            Upload csv file
          </Button>
        </ButtonContainer>
      </React.Fragment>
    );
  };

  const renderEmailList = () => {
    return (
      <div className="csvupload-email-list">
        <VerticalList
          itemList={props.studentsNotFound.map((email: any) => {
            return (
              <Typography color="dark" weight="500">
                {email}
              </Typography>
            );
          })}
        />
      </div>
    );
  };

  const renderStudentNotAddedWarning = () => {
    const student = props.studentsNotFound.length > 1 ? 'students' : 'student';
    return (
      <PageContainer>
        <PageHeader title={<TranslatedText i18nKey="GroupManager.CsvUpload.PageHeader" />} />
        <NoticeBoard
          type="danger"
          title={`${props.studentsNotFound.length} ${student} not added to your groups.`}
        >
          {`We could not find ${props.studentsNotFound.length} email addresses in your roster. The following students were not added to any groups. If some of these students have not yet signed up, you can always add them later.`}
        </NoticeBoard>
        {renderEmailList()}
        <Button
          type="primary"
          onClick={handleCloseUploadView}
          data-testid="finish-uploading-group-set"
        >
          Done
        </Button>
      </PageContainer>
    );
  };

  const groupCsvTemplateContent = `email,groupName
student1@kritik.io,"Group 1"
student2@kritik.io,"Group 2"
student3@kritik.io,"Group 1"
student4@kritik.io,"Group 4"
student5@kritik.io,"Group 4"
student6@kritik.io,"Group 1"
student7@kritik.io,"Group 6"
student8@kritik.io,"Group 1"
student9@kritik.io,"Group 3"`;
  const templateBlob = new Blob([groupCsvTemplateContent], { type: 'text/plain' });
  const templateFiles: FileItem[] = [
    {
      url: window.URL.createObjectURL(templateBlob),
      name: 'group-template.csv',
      _id: 1,
      download: true,
      size: 277,
    },
  ];

  const renderContent = () => {
    if (props.studentsNotFound.length > 0) {
      return renderStudentNotAddedWarning();
    }
    return (
      <PageContainer>
        <PageHeader title={<TranslatedText i18nKey="GroupManager.CsvUpload.Upload.PageHeader" />} />
        <FileList files={templateFiles} />
        <div className="csvupload-text-input">
          <FormField>
            <TextInput
              label="Group set name"
              value={groupSetName}
              onChange={handleGroupNameChange}
              testid="group-set-name-input"
            />
            <input
              hidden
              name="file"
              type="file"
              // @ts-expect-error TS(2322) FIXME: Type 'RefObject<unknown>' is not assignable to typ... Remove this comment to see the full error message
              ref={inputRef}
              accept=".csv"
              onClick={(e) => ((e.target as any).value = null)}
              onChange={handleNewFile}
              data-testid="upload-csv-file"
            />
          </FormField>
        </div>
        <FormField>{renderFileView()}</FormField>
        <FormSubmitButtons>
          <Button
            type="primary"
            onClick={handleUploadGroupSet}
            disabled={canUpload()}
            unavailable={canUpload()}
            data-testid="confirm-create-group-set"
          >
            Create Group Set
          </Button>
          <Button type="secondary" onClick={handleCloseUploadView}>
            Cancel
          </Button>
        </FormSubmitButtons>
        {groupGenerationError && (
          <NoticeBoard type="danger" title="Error Creating Groups">
            {groupGenerationError}
          </NoticeBoard>
        )}
      </PageContainer>
    );
  };

  return renderContent();
}

const mapStateToProps = (state: any) => {
  return {
    courseId: state.selected.courseId,
    isUploadingCsvFile: selectIsUploadingCsvFile(state),
    studentsNotFound: selectNotFoundStudents(state),
  };
};

export default connect(mapStateToProps, { closeUploadView, generateFromCSV, setNotAddedStudents })(
  CsvUploadView
);
