import { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'utils/withRouter';
import AccessibilityMessage from 'components/General/AccessibilityMessage';
import { ErrorModal, InfoModal, LoadingModal, ConfirmModal } from 'components/modals';
import Navigation from 'components/Navigation';
import LoadingBar from 'react-redux-loading-bar';
import classNames from 'classnames';
import images from 'images/registration';
import LiveChat from 'components/LiveChat';
import EnvironmentBar from 'components/EnvironmentBar';
import DemoBar from 'components/DemoBar';
import Wootric from 'components/Wootric';
import NoInstitutionWarning from 'components/NoInstitutionWarning';
import { Outlet } from 'react-router';
import { closeErrorModal } from '../actions/modals';
import ImpersonatingTopBar from 'components/ImpersonatingTopBar';
import { localize } from 'locales/index';
import PresenterModeTopBar from 'components/PresenterModeTopBar';

class AppContainer extends Component {
  componentDidMount(): void {
    this.updateContainerClasses();
  }

  componentDidUpdate(): void {
    this.updateContainerClasses();
  }

  updateContainerClasses(): void {
    const { router } = this.props as any;
    const containerClasses = classNames('main-wrapper', {
      'main-wrapper__top-nav':
        router.location.pathname === '/lms-connection-pending' ||
        (!this.isAuthenticated() && router.location.pathname !== '/demo'),
    });

    // Remove all existing main-wrapper classes
    document.body.classList.remove('main-wrapper', 'main-wrapper__top-nav');

    // Add the new classes
    containerClasses.split(' ').forEach((className) => {
      document.body.classList.add(className);
    });
  }

  isAuthenticated() {
    return (this.props as any).user.authenticated;
  }

  isTestUser() {
    // @ts-expect-error TS(2339) FIXME: Property 'user' does not exist on type 'Readonly<{... Remove this comment to see the full error message
    const { user } = this.props;
    return user.authUser && user.authUser.email && user.authUser.email.includes('kritik.io');
  }

  renderNavBar() {
    // @ts-expect-error TS(2339) FIXME: Property 'location' does not exist on type 'Readon... Remove this comment to see the full error message
    const { router, user } = this.props;
    if (router.location.pathname === '/lms-connection-pending') {
      return this.renderLinkToKritik();
    }
    if (this.isAuthenticated()) {
      return <Navigation user={user} />;
    }
    if (router.location.pathname !== '/demo') {
      return this.renderLinkToKritik();
    }
    return null;
  }

  getIntercomUser() {
    const { authUser } = (this.props as any).user;
    if (authUser && authUser.profile) {
      return {
        user_id: authUser.id,
        email: authUser.email,
        name: authUser.profile.name,
        role: authUser.role,
        isDemo: authUser.isDemo,
      };
    }
    return {};
  }

  renderLinkToKritik() {
    return (
      <div className="main-wrapper__link-to-home">
        <a href="https://www.kritik.io" target="_self">
          <img src={images.LOGO} alt={localize({ message: 'Navigation.HomePage.Logo' })} />
        </a>
      </div>
    );
  }

  render() {
    // @ts-expect-error TS(2339) FIXME: Property 'modal' does not exist on type 'Readon... Remove this comment to see the full error message
    const { modal, intercomId, router, intercomUserHash } = this.props;

    // @ts-expect-error TS(2304) FIXME: Cannot find name 'App'.
    const showEnvironmentBar = App.config.get('showEnvironmentBar');
    // @ts-expect-error TS(2304) FIXME: Cannot find name 'App'.
    const gitBranch = App.config.get('gitBranch');
    // @ts-expect-error TS(2304) FIXME: Cannot find name 'App'.
    const currentEnv = App.config.get('appEnv');
    // @ts-expect-error TS(2304) FIXME: Cannot find name 'App'.
    const isWootricEnabled = App.config.get('wootricEnabled');
    const shouldRenderWootric = isWootricEnabled && this.isAuthenticated() && !this.isTestUser();
    const user = this.getIntercomUser();
    const showDemoBar = user.isDemo;
    // @ts-expect-error TS(2304) FIXME: Cannot find name 'App'.
    const impersonate = App.config.get('impersonate');
    const isImpersonating = Boolean(impersonate);
    const isImpersonatingAsAdmin = Boolean(impersonate?.asAdmin);
    const intercomEnabled =
      // @ts-expect-error TS(2304) FIXME: Cannot find name 'App'.
      App.config.get('enableIntercom') && (!isImpersonating || isImpersonatingAsAdmin);

    const headerClassNames = classNames({
      'main-wrapper__side-nav':
        router.location.pathname !== '/lms-connection-pending' ||
        this.isAuthenticated() ||
        (!this.isAuthenticated && router.location.pathname === '/demo'),
    });

    return (
      <>
        <header className={headerClassNames}>
          {showEnvironmentBar && !showDemoBar && (
            <EnvironmentBar currentEnv={currentEnv} branch={gitBranch} />
          )}
          {showDemoBar && <DemoBar />}
          <div className="top-view-bar__wrapper">
            <ImpersonatingTopBar name={(this.props as any).user?.authUser?.profile?.name} />
            <PresenterModeTopBar />
          </div>
          <NoInstitutionWarning />
          <LoadingBar updateTime={3000} style={{ backgroundColor: '#2185d0', height: 2, top: 0 }} />
          {this.renderNavBar()}
        </header>
        {intercomEnabled && (
          <LiveChat
            email={user?.email}
            userHash={intercomUserHash}
            userId={user?.user_id}
            appID={intercomId}
          />
        )}

        <main className="main-wrapper__content">
          {/* {isLoggedIn && (
              <InlineInformation type="warning">
                <strong>IMPORTANT NOTE:</strong> Kritik will be undergoing scheduled maintenance on{' '}
                <strong>
                  Sunday, November 26, 2023, between 10am - 11am Eastern Standard Time.
                </strong>
                During that time, Kritik will be largely inaccessible. Please plan accordingly - we
                thank you for your understanding and apologize for any inconvenience.
              </InlineInformation>
            )} */}
          <Outlet />
        </main>
        <AccessibilityMessage />
        <ErrorModal
          open={modal.errorModal.open}
          title={modal.errorModal.title}
          content={modal.errorModal.content}
          handleClose={modal.errorModal.handleClose}
          btnText={modal.errorModal.btnText}
        />
        <InfoModal />
        <ConfirmModal />
        <LoadingModal />
        {shouldRenderWootric && <Wootric user={(this.props as any).user.authUser} />}
      </>
    );
  }
}

function mapStateToProps(state: any) {
  const { user, modal } = state;
  return {
    user,
    modal,
  };
}

export default withRouter(connect(mapStateToProps, { closeErrorModal })(AppContainer));
