import React from 'react';
import * as FormatUtils from '@kritik/utils/format';
import { localize } from 'locales/index';
import { TranslatedText } from 'components/TranslatedText';

function getLabels(dateString: any, status: any) {
  let date = '';
  let label = '';
  if (!dateString) {
    date = '';
    label = localize({ message: 'LmsGradeSync.SyncStatusLabel.NotSynced' });
  } else if (status === 'error') {
    date = FormatUtils.exactDate(dateString);
    label = localize({ message: 'LmsGradeSync.SyncStatusLabel.NotSynced' });
  } else {
    date = FormatUtils.exactDate(dateString);
    label = localize({ message: 'LmsGradeSync.SyncStatusLabel.Synced' });
  }

  return { date, label };
}
function SyncStatusLabel(props: any) {
  const { date, label } = getLabels(props.lastSyncAt, props.status);
  return (
    <div className="sync-status__label">
      <div className="sync-status__label-status" data-testid="sync-status">
        {label}
      </div>
      <div className="sync-status__label-date">{date}</div>
    </div>
  );
}

export default SyncStatusLabel;
