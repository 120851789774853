import React from 'react';
import classNames from 'classnames';
import { assignmentStatuses as STATUS_LABELS, PRESENT_STATUS } from '@kritik/constants/stage';
import ProgressTooltip from 'components/ActivityCard/Schedule/ProgressTooltip';
import localUtil from 'components/ActivityCard/Schedule/StatusNode/utils';
import StageResult from 'components/ActivityCard/Schedule/StatusNode/Outcome';
import STATUS_ICONS from 'images/status-icons';
import { localize } from 'locales/index';

const StatusNode = (props: any) => {
  const getDisabledIcon = () => {
    switch (props.status.name) {
      case STATUS_LABELS.CREATE:
        return STATUS_ICONS.CREATE_DISABLED;
      case STATUS_LABELS.EVALUATE:
        return STATUS_ICONS.EVALUATE_DISABLED;
      case STATUS_LABELS.FEEDBACK:
        return STATUS_ICONS.FEEDBACK_DISABLED;
      case STATUS_LABELS.PROCESSING3:
        return STATUS_ICONS.GRADING_DISABLED;
      case PRESENT_STATUS:
        return STATUS_ICONS.PRESENT_DISABLED;
      default:
        return null;
    }
  };

  const getActiveIcon = () => {
    switch (props.status.name) {
      case STATUS_LABELS.CREATE:
        return STATUS_ICONS.CREATE;
      case STATUS_LABELS.EVALUATE:
        return STATUS_ICONS.EVALUATE;
      case STATUS_LABELS.FEEDBACK:
        return STATUS_ICONS.FEEDBACK;
      case STATUS_LABELS.PROCESSING3:
        return STATUS_ICONS.GRADING;
      case PRESENT_STATUS:
        return STATUS_ICONS.PRESENT;
      default:
        return null;
    }
  };

  const getStatusIcon = () => {
    if (localUtil.isNodeActive(props.status, props.activity)) {
      return getActiveIcon();
    }
    return getDisabledIcon();
  };

  const classes = classNames('status-node', {
    'status-node--active': localUtil.isNodeActive(props.status, props.activity),
    'status-node--pending': localUtil.isNodeStartingNext(props.status, props.activity),
  });
  let statusName = props.status.name;
  if (statusName === 'Processing3') {
    statusName = 'Grading';
  }
  return (
    <ProgressTooltip activity={props.activity} status={props.status}>
      <div className={classes} data-testid={`activity-card-status-node-${props.status.name}`}>
        <div className="status-node__icon-wrapper">
          <img
            className="status-node__icon"
            src={getStatusIcon()}
            aria-label={localize({ message: 'StatusNode.StageAriaLabel', options: { statusName } })}
          />
          <div className="status-node__stage-result">
            <StageResult status={props.status} activity={props.activity} />
          </div>
        </div>
      </div>
    </ProgressTooltip>
  );
};

export default StatusNode;
