import { Field } from 'react-final-form';
import FormInputSwitch from 'components/Form/FormInputSwitch';
import { COURSE_FIELD_NAMES } from 'components/Course/Edit/constants';
import { localize } from 'locales/index';
import { useTranslation } from 'react-i18next';

type Props = {
  enableSimilarityReport: boolean;
  disabled?: boolean;
};
export function SimilarityReportSetting(props: Props) {
  const { t } = useTranslation();
  return (
    <div style={{ marginTop: '20px' }}>
      <h2 className="new-header-2">{t('CourseDetails.SimilarityReport.Header')}</h2>
      <p>{t('CourseDetails.SimilarityReport.Description')}</p>
      <Field name={COURSE_FIELD_NAMES.ENABLE_SIMILARITY_REPORT} type="checkbox">
        {({ input }) => {
          return (
            <FormInputSwitch
              checked={props.enableSimilarityReport}
              label={localize({ message: 'CourseDetails.SimilarityReport.Switch.Label' })}
              testId="switch-enable-similarity-report"
              name="enableSimilarityReport"
              onChange={input.onChange}
              disabled={props.disabled}
            />
          );
        }}
      </Field>
    </div>
  );
}
