import React, { useEffect, useState } from 'react';
import ColumnSelector from 'components/LmsGradeSync/ColumnSelector';
import LongArrow from 'images/lms-sync/long-arrow.svg';
import { connect } from 'react-redux';
import courseSelector from 'selectors/course';
import { isEdlinkLmsSetup } from '@kritik/utils/course';
import TableUtils from 'components/LmsGradeSync/LmsTable/ActivityTable/utils';
import { localize } from 'locales/index';
import { TranslatedText } from 'components/TranslatedText';

function OverallTable(props: any) {
  const isNewIntegration = props.course && isEdlinkLmsSetup(props.course);
  const getInitialColumn = () => {
    if (isNewIntegration && props.lmsColumns) {
      const columnId = props.course?.lms?.defaultLineItemIdLink;
      if (columnId) {
        const columnLabel = TableUtils.getColumnLabelByColumnId({
          columnId,
          lmsColumns: props.lmsColumns,
        });
        if (columnLabel === '') {
          return null;
        }
        return { id: columnId, label: columnLabel };
      }
      return null;
    }
    return props.selectedColumn;
  };

  const [column, setColumn] = useState(getInitialColumn());

  useEffect(() => {
    if (props.selectedColumn) {
      setColumn(props.selectedColumn);
    }
  }, [props.selectedColumn]);

  useEffect(() => {
    setColumn(getInitialColumn());
  }, [props.course.lms.defaultLineItemIdLink]);

  const handleColumnUpdate = ({ lineItem }: any) => {
    props.setSelectedColumn(lineItem);
  };
  const tableHead = () => {
    return (
      <div className="lms-table__header">
        <div className="lms-table__row-kritik-score">
          <div className="lms-table__header-text">
            <TranslatedText i18nKey="LmsGradeSync.OverallTable.KritikScores" />
          </div>
        </div>
        <div className="lms-table__row-arrow" />
        <div className="lms-table__row-lms-columns">
          <div className="lms-table__header-text">
            <TranslatedText i18nKey="LmsGradeSync.OverallTable.LmsColumns" />
          </div>
        </div>
      </div>
    );
  };

  const tableRow = () => {
    return (
      <div className="lms-table_row">
        <div className="lms-table__row-kritik-score">
          <h6>
            <TranslatedText i18nKey="LmsGradeSync.OverallTable.OverallScore" />
          </h6>
          <p>
            <TranslatedText i18nKey="LmsGradeSync.OverallTable.OverallScoreDescription" />
          </p>
        </div>
        <div className="lms-table__row-arrow">
          <img src={LongArrow} aria-hidden="true" />
        </div>
        <div className="lms-table__row-lms-columns">
          <ColumnSelector
            selectedColumn={column}
            setSelectedColumn={handleColumnUpdate}
            lmsColumns={props.lmsColumns}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="lms-table">
      {tableHead()}
      {tableRow()}
    </div>
  );
}

const mapStateToProps = (state: any) => {
  return {
    course: courseSelector.getCourse(state),
  };
};

export default connect(mapStateToProps)(OverallTable);
