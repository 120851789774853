import React from 'react';
import { connect } from 'react-redux';
import { createEvaluationDispute } from 'actions/activity';
import Confirm from 'components/modals/ConfirmModal';
import Button from 'components/buttons/Button';
import { InlineInformation, TextDisplay } from 'components/layout';
import NoticeBoard from 'components/layout/NoticeBoard';
import { isDispute, hasBeenDisputed, isDisputeResolved } from '@kritik/utils/creation/status';
import DisputeHandler from 'components/Creation/Disputing';
import { getErrorMessageFromResponse } from 'utils/error';
import { Card, CardActions, CardContent, Typography } from '@material-ui/core';
import FormFieldLabel from 'components/core/form/FieldLabel';

type OwnDisputeMenuState = {
  error: string | null;
  confirm: boolean;
  disputeReason: string;
  isSubmittingDispute: boolean;
  isOpen: boolean;
};

type DisputeMenuState = OwnDisputeMenuState & typeof DisputeMenu.defaultProps;

class DisputeMenu extends React.Component<{ isOpen: boolean }, DisputeMenuState> {
  textAreaRef: React.RefObject<HTMLTextAreaElement>;
  constructor(props: any) {
    super(props);
    this.state = {
      error: null,
      confirm: false,
      disputeReason: '',
      isSubmittingDispute: false,
      isOpen: props.isOpen,
    };

    this.textAreaRef = React.createRef();
  }

  static defaultProps = {
    isOpen: false,
  };

  componentDidUpdate(prevProps, prevState): void {
    if (this.props.isOpen && !prevState.isOpen) {
      this.textAreaRef.current?.focus();
    }
  }

  reportEvaluation() {
    if (this.state.isSubmittingDispute) {
      return;
    }
    this.setState({ isSubmittingDispute: true });
    if (this.state.disputeReason.length < 5) {
      return this.setState({
        confirm: false,
        error: 'Please leave a more descriptive reason for your dispute.',
        isSubmittingDispute: false,
      });
    }

    const data = {
      submissionId: (this.props as any).creation._id,
      dispute: {
        status: 'Dispute',
        disputedOn: new Date(Date.now()),
        reason: this.state.disputeReason,
      },
    };

    (this.props as any).createEvaluationDispute(data).catch((err: any) => {
      this.setState({
        confirm: false,
        error: getErrorMessageFromResponse(err),
        isSubmittingDispute: false,
      });
    });
  }

  isGroupLeader() {
    if (!(this.props as any).assignment.isGroupActivity) {
      return true;
    }
    const isLeader =
      (this.props as any).user.student.studentId === (this.props as any).creation.group.leader;
    return isLeader;
  }

  handleChange(event: any) {
    this.setState({ error: '', disputeReason: event.currentTarget.value });
  }

  renderDisputeEditor() {
    return (
      <Card>
        <CardContent>
          <Confirm
            isOpen={this.state.confirm}
            title="Are you sure you want to dispute this score?"
            description="Your instructor will review your peer evaluations and
            may or may not adjust your final score."
            onCancel={() => {
              return this.setState({ confirm: false });
            }}
            cancelButton="No"
            onConfirm={() => {
              return this.reportEvaluation();
            }}
            confirmButton="Yes"
          />

          <FormFieldLabel label="Dispute Score" id="dispute-reason" />
          <div className="dispute-form">
            {this.state.error && (
              <InlineInformation type="danger">{this.state.error}</InlineInformation>
            )}
            <textarea
              id="dispute-reason"
              rows={5}
              maxLength={280}
              placeholder="Let your instructor know why you are disputing your score"
              onChange={this.handleChange.bind(this)}
              value={this.state.disputeReason}
              data-testid="dispute-reason"
              ref={this.textAreaRef}
            />
            <div>
              {!this.isGroupLeader() && (
                <NoticeBoard
                  title={`Only your group leader can dispute your group's score.`}
                  type="information"
                  style={{ marginBottom: '20px' }}
                >
                  Co-ordinate with your group leader to submit a dispute.
                </NoticeBoard>
              )}
            </div>
          </div>
        </CardContent>
        <CardActions style={{ justifyContent: 'end' }}>
          <Button
            onClick={() => {
              return this.setState({ confirm: true });
            }}
            type={this.isGroupLeader() ? 'primary' : 'secondary'}
            disabled={!this.isGroupLeader()}
            data-testid="submit-dispute"
          >
            Submit Dispute
          </Button>
        </CardActions>
      </Card>
    );
  }

  renderDisputeView() {
    // @ts-expect-error TS(2339) FIXME: Property 'creation' does not exist on type 'Readon... Remove this comment to see the full error message
    const { creation } = this.props;
    return (
      <NoticeBoard title="Dispute Status">
        <div>{isDispute(creation) ? 'Pending Review' : 'Resolved'}</div>
        <div className="dispute-form">
          <span className="dispute-form--section-label">Your reason for disputing:</span>
          <TextDisplay content={creation.dispute.reason} />
          {creation.dispute.teacherComment && (
            <React.Fragment>
              <span className="dispute-form--section-label">Instructor comment:</span>
              <TextDisplay content={creation.dispute.teacherComment} />
            </React.Fragment>
          )}
          {creation.dispute.status === 'Resolved' ? (
            <div className="dispute-form--disclaimer">
              Your dispute has been resolved by your instructor. Your score may or may not have been
              changed as a result.
            </div>
          ) : (
            <div className="dispute-form--disclaimer">
              Your dispute has been sent to your instructor and is under review.
            </div>
          )}
        </div>
      </NoticeBoard>
    );
  }

  render() {
    if (!(this.props as any).isOpen) {
      return null;
    }
    if (!hasBeenDisputed((this.props as any).creation)) {
      return this.renderDisputeEditor();
    }
    if (isDisputeResolved((this.props as any).creation)) {
      return <DisputeHandler creation={(this.props as any).creation} />;
    }
    return this.renderDisputeView();
  }
}

const mapStateToProps = (state: any) => {
  return {
    user: state.user,
  };
};

export default connect(mapStateToProps, {
  createEvaluationDispute,
})(DisputeMenu);
