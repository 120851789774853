import React from 'react';
import moment from 'moment-timezone';
import { connect } from 'react-redux';
import Button from 'components/buttons/Button';
import { openInfoModal, closeInfoModal } from 'actions/modals';
import { selectIsDifferentOffSet, selectTimeZone } from 'selectors/user';
import FormCheckbox from 'components/core/input/Checkbox';
import FieldInfo from 'components/core/form/FieldInfo';
import { GracePeriodSelect } from 'components/Assignment/scheduler';
import Typography from 'components/core/Typography';
import { localize } from 'locales/index';
import { TranslatedText } from 'components/TranslatedText';

function GracePeriodCheckBox({ handleCheck, isCheckedGracePeriod, disabled }: any) {
  const checkboxLabel = (
    <Typography color={disabled ? 'light' : 'primary'} weight="500">
      <TranslatedText i18nKey="GracePeriod.SubmissionGracePeriod" />
    </Typography>
  );
  return (
    <FormCheckbox
      onChange={handleCheck}
      isChecked={isCheckedGracePeriod}
      isDisabled={disabled}
      label={checkboxLabel}
      type="secondary"
      testid="grace-period-checkbox"
    />
  );
}

GracePeriodCheckBox.defaultProps = {
  disabled: false,
};

function GracePeriod({
  handleCheck,
  selectedGracePeriod,
  isCheckedGracePeriod,
  isLocked,
  creationEndDate,
  openInfoModal,
  closeInfoModal,
  isDifferentOffSet,
  timeZone,
  ...props
}: any) {
  const OKButton = (
    <Button
      onClick={closeInfoModal}
      type="primary"
      label={localize({ message: 'Button.Label.CloseInfoModal' })}
    >
      OK
    </Button>
  );
  function SectionWrapper({ children }: any) {
    return <div style={{ marginBottom: 10 }}>{children}</div>;
  }
  const learnMoreContent = (
    <div>
      <SectionWrapper>
        <b>
          <TranslatedText i18nKey="GracePeriod.OverdueSubmissions.Title" />
        </b>
        <p>
          <TranslatedText i18nKey="GracePeriod.OverdueSubmissions.Description" />
        </p>
      </SectionWrapper>
      <SectionWrapper>
        <b>
          <TranslatedText i18nKey="GracePeriod.OnTimeSubmissions.Title" />
        </b>
        <p>
          <TranslatedText i18nKey="GracePeriod.OnTimeSubmissions.Description" />
        </p>
      </SectionWrapper>
      <SectionWrapper>
        <b>
          <TranslatedText i18nKey="GracePeriod.MarkedAsLate.Title" />
        </b>
        <p>
          <TranslatedText i18nKey="GracePeriod.MarkedAsLate.Description" />
        </p>
      </SectionWrapper>
    </div>
  );

  const handleClickLearnMore = () => {
    openInfoModal({
      open: true,
      actions: [OKButton],
      title: localize({ message: 'GracePeriod.ModalTitle' }),
      content: learnMoreContent,
      type: 'info',
      callback: false,
      messageId: 'grace-period-info',
    });
  };

  function isToggleGracePeriodDisabled() {
    const now = moment().toDate();
    return now >= creationEndDate || isLocked;
  }

  const handleSelect = (interval: any) => {
    const now = moment().toDate();
    const endDate = moment(creationEndDate);
    // @ts-expect-error TS(2365) FIXME: Operator '<' cannot be applied to types 'Moment' a... Remove this comment to see the full error message
    if (endDate.add(interval, 'minutes') < now) {
      return;
    }
    return props.handleSelect(interval);
  };

  // once grace period start, should not allow user to toggle off
  // but can still select different grace period
  const disableToggleGracePeriod = isToggleGracePeriodDisabled();
  return (
    <div test-id="grace-period-select">
      <GracePeriodCheckBox
        handleCheck={handleCheck}
        isCheckedGracePeriod={isCheckedGracePeriod}
        disabled={disableToggleGracePeriod}
      />
      <div className="scheduler-grace-period__info">
        <FieldInfo
          label={
            <React.Fragment>
              <TranslatedText i18nKey="GracePeriod.Info.Description" />{' '}
              <a
                onClick={handleClickLearnMore}
                tabIndex={0}
                title={localize({ message: 'Title.Text.GracePeriod' })}
              >
                <TranslatedText i18nKey="GracePeriod.Info.LearnMore" />
              </a>
            </React.Fragment>
          }
        />
        {isCheckedGracePeriod && (
          <GracePeriodSelect
            handleSelect={handleSelect}
            selectedGracePeriod={selectedGracePeriod}
            disabled={isLocked}
            creationEndDate={creationEndDate}
          />
        )}
      </div>
    </div>
  );
}

GracePeriod.defaultProps = {
  selectedGracePeriod: 2,
  isLocked: false,
  isCheckedGracePeriod: false,
};

const mapStateToProps = (state: any) => {
  return {
    isDifferentOffSet: selectIsDifferentOffSet(state),
    timeZone: selectTimeZone(state),
  };
};

export default connect(mapStateToProps, {
  openInfoModal,
  closeInfoModal,
})(GracePeriod);
