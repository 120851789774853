import { InlineInformation } from 'components/layout';
import { TranslatedText } from 'components/TranslatedText';
import { localize } from 'locales/index';

export const InviteCallout = () => {
  return (
    <InlineInformation
      type="attention"
      title={localize({ message: 'RosterManager.Banners.InviteCallout.Title' })}
    >
      <TranslatedText i18nKey="RosterManager.Banners.InviteCallout.Content" />
    </InlineInformation>
  );
};

export default {
  InviteCallout,
};
