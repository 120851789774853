import { useState } from 'react';
import { connect } from 'react-redux';
import * as _ from 'lodash-es';

import { selectCreationTableListBy } from 'redux/creationTable/select';
import NoticeBoard from 'components/layout/NoticeBoard';
import { EvaluationScoreView } from 'components/Creation/GradeView';
import { isListByGroup } from 'components/CreationList/util';
import * as ActivityStatusUtil from '@kritik/utils/stage';
import * as EvaluationUtil from '@kritik/utils/creation/evaluation';
import TabMenu, { TabMenuUtils } from 'components/layout/TabMenu';
import { CreationScoreUtil, GradingScoreUtil } from '@kritik/utils/grade';
import StudentContent from 'components/layout/StudentContent';
import { CreationView } from 'components/Creation/Create/View';
import * as StudentUtils from 'utils/student';
import { DisplayBox } from 'components/layout';
import Spinner from 'components/Loaders/Spinner';
import ScoreItem from 'components/DisplayBox/ScoreItem';
import StarRubricMarks from 'components/General/StarRubricMarks';
import WrittenEvaluationPanel from 'components/Assignment/FinalizedPanels/WrittenEvaluation';
import { EvaluationView } from 'components/Creation/Evaluate';
import { Feedback } from 'components/Creation/Feedback';
import { LateSubmissionWrapper, MissedWork } from 'components/Assignment/NoticeBoards';
import EvaluationScoreDisplay from 'components/Creation/CreationOverview/EvaluationTab/EvaluationScoreDisplay';
import * as ActivityUtils from '@kritik/utils/activity';
import StudentSelectors from 'selectors/student';
import { subTimeFromCreationDue } from '@kritik/utils/format';

import { localize } from 'locales/index';
import InfoPopup from 'components/core/InfoPopup';
import { STAR_RUBRIC_MARKS } from 'components/General/constant';
import { useGetCreationsToEvaluate } from 'hooks/creations';
import { useUserRoleInCourse } from 'hooks/course';
import { LateEvaluationBoard } from './LateEvaluation';
import { TranslatedText } from 'components/TranslatedText';

function EvaluationTab(props: any) {
  const [selectedCreationTab, setSelectedCreationTab] = useState(0);
  const { data: creationsToEvaluate, isLoading } = useGetCreationsToEvaluate({
    activityId: props.activity._id,
    courseId: props.course._id,
    studentId: props.student._id,
  });
  const { isInstructorInCourse } = useUserRoleInCourse();

  if (isLoading) {
    return (
      <div className="scoring-creation-table__loader--active">
        <Spinner />
      </div>
    );
  }

  const renderFinalizedStudentsEvaluations = () => {
    const getEvaluations = () => {
      const studentEvaluations: any = [];
      creationsToEvaluate.forEach((creation: any) => {
        const studentId = props.student._id;
        const evaluation = EvaluationUtil.getEvaluationByStudentId(creation.scores, studentId);
        studentEvaluations.push(evaluation);
      });
      return studentEvaluations;
    };

    const hasCompletedAnyEvals = (evaluations: any) => {
      return evaluations.reduce((accum: any, curr: any) => {
        return accum || curr;
      }, false);
    };

    const evaluations = getEvaluations();

    if (!hasCompletedAnyEvals(evaluations)) {
      return (
        <NoticeBoard type="danger" title="">
          <TranslatedText i18nKey="EvaluationTab.NoPeerEvaluationsCompletedContent" />
        </NoticeBoard>
      );
    }

    const getEvalTabMenuLabels = () => {
      const labels = evaluations.map((evaluation, i) => {
        return {
          id: i,
          title: `${localize({ message: 'EvaluationTab.Evaluation' })} ${i + 1}`,
          status: TabMenuUtils.getEvaluationTabStatus({
            evaluation,
            activity: props.activity,
          }),
        };
      });
      return labels;
    };

    const peersCreation = creationsToEvaluate[selectedCreationTab];
    const studentsEval = evaluations[selectedCreationTab];

    const comparisonMarks = CreationScoreUtil.getRawCreationScore(
      peersCreation,
      props.activity.rubric
    );

    const gradingSkillsChangesPerEval =
      GradingScoreUtil.getGradingSkillsChangeFromEval(studentsEval);

    const maxGradingSkillsChange = GradingScoreUtil.getMaxScoreChangePerAssignment(
      ActivityUtils.isCalibrationActivity(props.activity)
    );

    const maxGradingScorePerEval = maxGradingSkillsChange / creationsToEvaluate.length;
    const gradingScorePerEval = GradingScoreUtil.calcScorePerEval(
      gradingSkillsChangesPerEval,
      maxGradingScorePerEval
    );

    const peersCreationStudent =
      typeof peersCreation.student === 'string'
        ? (props.students || []).find((student) => peersCreation.student === student._id)
        : peersCreation.student;

    const getPopoverDescription = () => {
      return (
        <>
          <p>{localize({ message: 'GradingScoreTable.Popover.Description1' })}</p>
          <p>
            {localize({ message: 'GradingScoreTable.Popover.Description2' })}
            <a
              target="_blank"
              href={
                'https://help.kritik.io/en/articles/6845495-grading-power-and-scoring-in-kritik'
              }
            >
              {localize({ message: 'GradingScoreTable.Popover.LinkName' })}
            </a>
          </p>
        </>
      );
    };

    return (
      <>
        <TabMenu
          labels={getEvalTabMenuLabels()}
          onSelect={(idx: any) => {
            return setSelectedCreationTab(idx);
          }}
          activeLabel={selectedCreationTab}
        />
        {studentsEval ? (
          <div style={{ marginTop: '10px' }}>
            <LateEvaluationBoard
              evaluation={studentsEval}
              activity={props.activity}
              evaluatedCreation={peersCreation}
            />
            <StudentContent
              user={peersCreation.user}
              score={StudentUtils.getNewKS(peersCreationStudent)}
              label="Creator"
            >
              <CreationView submission={peersCreation} />
            </StudentContent>
            <DisplayBox
              header={
                <ScoreItem
                  value={gradingScorePerEval}
                  title={localize({ message: 'GradingScoreTable.Title' })}
                  description={localize({ message: 'GradingScoreTable.Subtitle' })}
                  size="lg"
                  className="section-padding-bottom"
                  subtitle={
                    <InfoPopup
                      btnClassName="padding-top-0"
                      title={localize({ message: 'GradingScoreTable.Popover.Title' })}
                      description={getPopoverDescription()}
                    />
                  }
                />
              }
            >
              <StarRubricMarks
                tableType={STAR_RUBRIC_MARKS.GRADING_SCORE}
                rubric={props.activity.rubric}
                marks={studentsEval.marks}
                type="primary"
                maxGradingScorePerEval={maxGradingScorePerEval}
                scoreDiffs={
                  studentsEval.gradingSkillsChange.length ? studentsEval.gradingSkillsChange : null
                }
                comparisonMarks={[{ marks: comparisonMarks, type: 'final-score' }]}
              />
            </DisplayBox>
            {!ActivityUtils.isCalibrationActivity(props.activity) && (
              <WrittenEvaluationPanel
                peersCreation={peersCreation}
                submission={props.creation}
                studentsEval={studentsEval}
                assignment={props.activity}
                isInstructor={isInstructorInCourse}
                student={props.student}
              />
            )}
          </div>
        ) : (
          <div style={{ marginTop: '10px' }}>
            <StudentContent
              user={peersCreation.user}
              score={StudentUtils.getNewKS(peersCreation.student)}
              label="Creator"
            >
              <CreationView submission={peersCreation} />
            </StudentContent>
            <MissedWork field="evaluation" />
          </div>
        )}
      </>
    );
  };

  const renderGroupEvaluationSummary = () => {
    return (
      <EvaluationScoreView
        assignment={props.activity}
        submissionsToEvaluate={creationsToEvaluate}
        student={props.student}
        course={props.course}
        submission={props.creation}
        groupView
      />
    );
  };

  if (ActivityUtils.isPresentationActivity(props.activity)) {
    const evaluationStage = ActivityStatusUtil.getEvaluationStage(props.activity);
    const message = localize({
      message: ActivityUtils.isGroupPresentationActivity(props.activity)
        ? 'Activity.GroupPresentation.Overview.Evaluation.Group.NotRequired'
        : 'Activity.IndividualPresentation.Overview.Evaluation.Individual.NotRequired',
    });

    const participatingStudentIdsInEvaluateStage = (evaluationStage.students || [])
      .filter((s) => s.isParticipating)
      .map((s) => s.student);

    const isStudentParticipating = participatingStudentIdsInEvaluateStage.includes(
      props.student._id
    );

    if (!isStudentParticipating) {
      return (
        <div className="kritik-form-container">
          <div className="submission-view-header">
            <h3>{localize({ message: 'Activity.Evaluation' })}</h3>
          </div>
          {message}
        </div>
      );
    }
  }

  if (creationsToEvaluate.length === 0) {
    return (
      <NoticeBoard
        type="information"
        title={localize({ message: 'EvaluationTab.NoEvaluationsCompletedTitle' })}
      >
        <TranslatedText i18nKey="EvaluationTab.NoEvaluationsCompletedContent" />
      </NoticeBoard>
    );
  }

  if (isListByGroup(props.creationTableListBy) && props.isGroupView) {
    return renderGroupEvaluationSummary();
  }

  const renderEvaluationStage = (activity: any, creation: any, userScore: any) => {
    return (
      <>
        <LateEvaluationBoard
          evaluation={userScore}
          activity={props.activity}
          evaluatedCreation={creation}
        />
        <EvaluationView
          assignment={activity}
          submission={creation}
          userScore={userScore}
          showScoreLabel
        />
        {userScore && userScore.feedbackOnFeedback.motivational !== 0 ? (
          <Feedback assignment={activity} submission={creation} evaluation={userScore} />
        ) : null}
      </>
    );
  };

  const renderStudentsEvaluations = () => {
    const isGroupActivity =
      ActivityUtils.isGroupAssignment(props.activity) ||
      ActivityUtils.isGroupPresentationActivity(props.activity);
    const title = isGroupActivity ? 'Group' : 'Peer';
    return (
      <div data-testid="evaluation-entries">
        {creationsToEvaluate.map((submission: any, index: any) => {
          let userScore = _.filter(submission.scores, (score) => {
            // TODO: remove check on user type when data from server is 100% normalized
            return (score.user._id || score.user) === props.student.user._id;
          });
          userScore = userScore.length ? userScore[0] : false;

          const submissionStudent =
            typeof submission.student === 'string'
              ? (props.students || []).find((student) => submission.student === student._id)
              : submission.student;

          const evaluateStage = ActivityStatusUtil.getEvaluationStage(props.activity);

          return (
            <div className="evaluation-entry">
              {userScore.submissionStatus === 'GRACE' && (
                <LateSubmissionWrapper
                  title={
                    <TranslatedText
                      i18nKey="Evaluation.GracePeriod.NoticeBoard.Title"
                      values={{
                        difference: subTimeFromCreationDue(
                          evaluateStage.endDate,
                          userScore.updatedAt
                        ),
                      }}
                    />
                  }
                >
                  <TranslatedText i18nKey="Evaluation.GracePeriod.NoticeBoard.Content" />
                </LateSubmissionWrapper>
              )}
              <StudentContent
                user={submission.user}
                score={StudentUtils.getNewKS(submissionStudent)}
                group={isGroupActivity ? submission.group : null}
                testid="creation-to-evaluate"
              >
                <CreationView title={`${title} Creation ${index + 1}`} submission={submission} />
              </StudentContent>
              <StudentContent
                user={props.student.user}
                score={StudentUtils.getNewKS(props.student)}
              >
                <div className="evaluation-entry__evaluation">
                  {renderEvaluationStage(props.activity, submission, userScore)}
                </div>
              </StudentContent>
            </div>
          );
        })}
      </div>
    );
  };

  const scoreHistory = StudentUtils.getStudentActivityScoreHistory(
    props.scoreHistories,
    props.activity._id
  );

  return (
    <div>
      <EvaluationScoreDisplay
        activity={props.activity}
        submissionsToEvaluate={creationsToEvaluate}
        student={props.student}
        course={props.course}
        creation={props.creation}
      />
      <div className="submission-overview__evaluation-header">
        <h3>
          <TranslatedText
            i18nKey="EvaluationTab.EvaluationsGivenTitle"
            values={{ name: props.student.user.profile.name }}
          />
        </h3>
      </div>
      <p>
        <TranslatedText i18nKey="EvaluationTab.EvaluationsGivenContent" />
      </p>
      {scoreHistory || ActivityStatusUtil.isFinalized({ assignment: props.activity })
        ? renderFinalizedStudentsEvaluations()
        : renderStudentsEvaluations()}
    </div>
  );
}

const mapStateToProps = (state: any, ownProps: any) => {
  return {
    students: StudentSelectors.selectActiveStudentInCurrentCourse(state),
    creationTableListBy: selectCreationTableListBy(state),
    user: state.user,
    scoreHistories: StudentSelectors.selectStudentScoreHistories(state, ownProps.student._id),
  };
};

export default connect(mapStateToProps, {
  selectCreationTableListBy,
})(EvaluationTab);
