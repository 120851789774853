import { useState } from 'react';
import { connect } from 'react-redux';
import Button from 'components/buttons/Button';
import ButtonContainer from 'components/buttons/ButtonContainer';
import ButtonWithSelect from 'components/buttons/ButtonWithSelect';
import CreateTemplateModal from 'components/ActivityEdit/Rubric/Menu/CreateTemplateModal';
import UpdateRubricModal from 'components/ActivityEdit/Rubric/Menu/UpdateRubricModal';
import FullScreenModal from 'components/modals/FullScreenModal';
import RubricManager from 'components/RubricManager';
import FormTitle from 'components/core/form/Title';
import Typography from 'components/core/Typography';
import { setSelectedRubricId } from 'redux/rubricManager/actions';
import { localize } from 'locales/index';
import { TranslatedText } from 'components/TranslatedText';

const RubricTitle = (props: any) => {
  return (
    <>
      <FormTitle
        size="md"
        label={localize({ message: 'ActivityEdit.Rubric.Menu.RubricTemplateLabel' })}
      />
      <Typography color="dark">{props.rubric.name}</Typography>
    </>
  );
};

const Menu = (props: any) => {
  const [showUpdateRubricModal, setShowUpdateRubricModal] = useState(false);
  const [showCreateNewTemplateModal, setShowCreateNewTemplateModal] = useState(false);
  const [showRubricManager, setShowRubricManager] = useState(false);

  const onSelectSaveOption = (e: any) => {
    switch (e.target.value) {
      case 'update_template':
        return setShowUpdateRubricModal(true);
      case 'new_template':
        return setShowCreateNewTemplateModal(true);
      default:
        return null;
    }
  };

  const onClickManageTemplate = () => {
    setShowRubricManager(true);
  };

  const handleUpdateTemplateComplete = (updatedRubricId: any) => {
    setShowUpdateRubricModal(false);
    props.setSelectedRubricId(updatedRubricId);
    setShowRubricManager(true);
  };

  const handleCreateTemplateComplete = () => {
    setShowCreateNewTemplateModal(false);
    setShowRubricManager(true);
  };

  return (
    <div className="activity-edit-rubric-menu">
      <div className="activity-edit-rubric-menu__title" data-testid="rubric-title">
        <RubricTitle rubric={props.rubric} />
      </div>
      <div className="activity-edit-rubric-menu__options">
        <ButtonContainer>
          <ButtonWithSelect
            options={[
              {
                label: localize({ message: 'ActivityEdit.Rubric.Menu.NewTemplateOption' }),
                value: 'new_template',
                testid: 'save-as-new-template',
              },
              {
                label: localize({ message: 'ActivityEdit.Rubric.Menu.UpdateTemplateOption' }),
                value: 'update_template',
                testid: 'update-template',
              },
            ]}
            title={localize({ message: 'ActivityEdit.Rubric.Menu.SaveAsButtonTitle' })}
            onChange={onSelectSaveOption}
            testid="save-as-button"
          />
          <Button
            type="secondary"
            onClick={onClickManageTemplate}
            data-testid="manage-templates"
            label={localize({ message: 'Button.Label.ManageRubricTemplates' })}
          >
            <TranslatedText i18nKey="ActivityEdit.Rubric.Menu.ManageTemplatesButton" />
          </Button>
        </ButtonContainer>
      </div>
      <UpdateRubricModal
        isOpen={showUpdateRubricModal}
        onCancel={() => {
          setShowUpdateRubricModal(false);
        }}
        onComplete={handleUpdateTemplateComplete}
        rubric={props.rubric}
      />
      {showCreateNewTemplateModal && (
        <CreateTemplateModal
          isOpen={showCreateNewTemplateModal}
          onCancel={() => {
            setShowCreateNewTemplateModal(false);
          }}
          onComplete={handleCreateTemplateComplete}
          rubric={props.rubric}
        />
      )}
      <FullScreenModal open={showRubricManager}>
        <RubricManager
          goBackAction={() => setShowRubricManager(false)}
          coreAction={{
            label: localize({ message: 'ActivityEdit.Rubric.Menu.UseButtonLabel' }),
            testid: 'use-template',
            action: (rubric: any) => {
              props.onChangeTemplate(rubric);
              setShowRubricManager(false);
            },
          }}
        />
      </FullScreenModal>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {};
};

export default connect(mapStateToProps, {
  setSelectedRubricId,
})(Menu);
